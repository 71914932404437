import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles
} from "@material-ui/core";
import {AppContext} from '../../context';
import {useDataAccess} from "../custom_hooks";
import {parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(2),
        color: theme.palette.text.customPrimary,
    },
    input: {
        display: 'none',
        color: theme.palette.text.customPrimary,
    },
    text: {
        color: theme.palette.text.customPrimary,
    },
}));

const AddImageDialog = props => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState
    } = useContext(AppContext);
    const { fetch } = useDataAccess();
    const [file, setFile] = useState();

    const handleChangeFile = event => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleConfirm = () => {
        const data = new FormData();
        data.append("file", file);
        fetchInitAppState();
        fetch(
            `media_objects`,
            {
                method: 'POST',
                body: data
            }
        )
            .then(response => {
                return response.json();
            })
            .then(json => {
                fetchSuccessAppState();
                props.onConfirm(json['@id']);
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleCancel = () => {
        props.onClose();
    };

    const handleClose = () => {
        props.onClose();
    };

    return (
        <Dialog
            fullWidth
            maxWidth='xs'
            onClose={() => handleClose}
            aria-labelledby="dialog-title"
            open={props.open}
        >
            <DialogTitle id="dialog-title" className={classes.text}>{t(props.title)}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" className={classes.text}>
                    {t(props.content)}
                </DialogContentText>
            </DialogContent>
            <input
                accept="image/*"
                className={classes.input}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleChangeFile}
            />
            <label htmlFor="raised-button-file">
                <Button raised="true" component="span" className={classes.button}>
                    Upload
                </Button>
                <span className={classes.text}>
                    {file && file.name}
                    {!file && <span>{t('no_file_choosen')}</span>}
                </span>
            </label>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary" className={classes.button}>
                    {t('cancel')}
                </Button>
                <Button onClick={handleConfirm} color="primary" className={classes.button}>
                    {t('submit')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddImageDialog;
import * as ACTION_TYPES from '../actions/action_types';

export const initialState = {
  isLoading: false,
  isError: false,
  isLoggedIn: false,
  isRegistered: false,
  isPasswordCreated: false,
  isUserConfirmed: false,
  user: null,
  error: null
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.AUTH_REDUCER_INIT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isLoggedIn: false,
        isRegistered: false,
        isPasswordCreated: false,
        isUserConfirmed: false,
        user: null,
        error: null
      };
    case ACTION_TYPES.AUTH_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
        isRegistered: false,
        isPasswordCreated: false,
        isUserConfirmed: false,
        error: null
      };
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isLoggedIn: true,
        user: action.payload,
        error: null
      };
    case ACTION_TYPES.REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isRegistered: true,
        error: null
      };
    case ACTION_TYPES.CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isPasswordCreated: true,
        error: null
      };
    case ACTION_TYPES.CONFIRM_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isUserConfirmed: true,
        error: null
      };
    case ACTION_TYPES.AUTH_REFRESH:
      return {
        ...state,
        user: {
            ...state.user,
            [action.key]: action.data
        },
      };
    case ACTION_TYPES.AUTH_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isLoggedIn: false,
        isRegistered: false,
        isPasswordCreated: false,
        isUserConfirmed: false,
        user: null,
        error: action.error
      };
    default:
      return state;
  }
};
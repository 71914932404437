import {useEffect, useReducer} from 'react';
import * as AppReducer from "../../store/hooks_state/appReducer";
import * as ACTION_TYPES from '../../store/actions/action_types';

const useAppState = () => {
    const localAppState = JSON.parse(localStorage.getItem('appState'));
    const [appState, dispatch] = useReducer(AppReducer.appReducer, localAppState || AppReducer.initialState);

    useEffect(() => {
        localStorage.setItem('appState', JSON.stringify(appState));
    }, [appState]);

    const initAppState = () => {
        dispatch({ type: ACTION_TYPES.APP_STATE_INIT });
    };

    const initCachedDataAppState = () => {
        dispatch({ type: ACTION_TYPES.APP_STATE_INIT_CACHED_DATA });
    };

    const fetchInitAppState = () => {
        dispatch({ type: ACTION_TYPES.APP_STATE_FETCH_INIT });
    };

    const fetchAbortAppState = () => {
        dispatch({ type: ACTION_TYPES.APP_STATE_FETCH_ABORT });
    };

    const fetchSuccessAppState = () => {
        dispatch({ type: ACTION_TYPES.APP_STATE_FETCH_SUCCESS });
    };

    const fetchErrorAppState = error => {
        dispatch({ type: ACTION_TYPES.APP_STATE_FETCH_ERROR, error: error });
    };

    const updateCachedDataAppState = (key, index, data) => {
        dispatch({ type: ACTION_TYPES.APP_STATE_UPDATE_CACHED_DATA, key: key, index: index, data: data });
    };

    const addRefreshResourceAppState = resource => {
        dispatch({ type: ACTION_TYPES.APP_STATE_REFRESH_ADD_RESOURCE, resource: resource });
    };

    const forceRefreshResourceAppState = resource => {
        dispatch({ type: ACTION_TYPES.APP_STATE_FORCE_REFRESH_RESOURCE, resource: resource });
    };

    const removeRefreshResourceAppState = resource => {
        dispatch({ type: ACTION_TYPES.APP_STATE_REFRESH_REMOVE_RESOURCE, resource: resource });
    };

    const setRefreshStatusAppState = value => {
        dispatch({ type: ACTION_TYPES.APP_STATE_REFRESH_STATUS, value: value });
    };

    const setRefreshViewAppState = value => {
        dispatch({ type: ACTION_TYPES.APP_STATE_REFRESH_VIEW, value: value });
    };
    const initMessageAppState = () => {
        dispatch({ type: ACTION_TYPES.APP_STATE_INIT_MESSAGE });
    };

    const showMessageAppState = (variant, message) => {
        dispatch({ type: ACTION_TYPES.APP_STATE_SHOW_MESSAGE, variant: variant, message: message });
    };

    const setPageInfos = (pageTitle, isMainPage) => {
        dispatch({ type: ACTION_TYPES.APP_STATE_SET_PAGE_INFOS, pageTitle: pageTitle, isMainPage: isMainPage });
    };

    return {
        appState,
        initAppState,
        initCachedDataAppState,
        fetchInitAppState,
        fetchAbortAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        updateCachedDataAppState,
        addRefreshResourceAppState,
        forceRefreshResourceAppState,
        removeRefreshResourceAppState,
        setRefreshStatusAppState,
        setRefreshViewAppState,
        initMessageAppState,
        showMessageAppState,
        setPageInfos
    };
};

export default useAppState;
import React from 'react';
import {Container, Grid, makeStyles} from "@material-ui/core";
import Logo from "./Logo";

const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: '#1d3856',
        zIndex: 1,
    }
}));

const Loader = () => {
    const classes = useStyles();

    return (
        <Container>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
                className={classes.root}
            >
                <Logo />
            </Grid>
        </Container>
    )
};

export default Loader;
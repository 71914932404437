import React, {useContext, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
    Divider,
    Fab,
    Box,
    TextField,
    IconButton,
    List as MaterialList,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import {Add, Check, Clear, Delete, Edit} from '@material-ui/icons';
import {AppContext, AuthContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {ConfirmDialog} from "../dialog";
import {getIdFromUri, parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    list: {
        marginBottom: theme.spacing(5),
    },
    newValue: {
        maxWidth: '50%',
    },
    addFab: {
        zIndex: '1000',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    icon: {
        color: theme.palette.primary.main,
    },
}));

const List = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        updateCachedDataAppState,
        addRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const classes = useStyles();
    const {fetch} = useDataAccess();
    const [openDialog, setOpenDialog] = useState(false);
    const [customDeliveryCosts, setCustomDeliveryCosts] = useState([]);
    const [customDeliveryCostId, setCustomDeliveryCostId] = useState(0);
    const [visible, setVisible] = useState(0);

    const handleUpdate = () => {
        fetchInitAppState();
        fetch(
            `custom_delivery_costs/${customDeliveryCostId}`,
            {
                method: 'PUT',
                body: {
                    value: parseFloat(inputs.value)
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                updateCachedDataAppState('custom_delivery_costs', customDeliveryCostId, {...appState.cachedData.custom_delivery_costs[customDeliveryCostId], 'value': inputs.value});
                showMessageAppState('success', t('custom_delivery_cost_updated'));
                handleCancelEdit();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };
    const {inputs, handleInputChange, handleSubmit, setInputs} = useForm(handleUpdate);

    useEffect(() => {
        setPageInfos('custom_delivery_costs', false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCustomDeliveryCostFromCache();
        addRefreshResourceAppState(`custom_delivery_costs?seller=${authState.user.organizationId}&purchaser=${props.match.params.idPurchaser}`);
        // eslint-disable-next-line
    }, [props.match.params.idPurchaser]);

    useEffect(() => {
        setCustomDeliveryCostFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.custom_delivery_costs]);

    useEffect(() => {
        setInputs(inputs => ({...inputs, ...customDeliveryCosts}));
        // eslint-disable-next-line
    }, [customDeliveryCosts]);

    const setCustomDeliveryCostFromCache = () => {
        if(
            authState &&
            authState.user &&
            authState.user.organizationId &&
            props.match.params.idPurchaser &&
            appState.cachedData &&
            appState.cachedData.custom_delivery_costs
        ) {
            setCustomDeliveryCosts(
                Object.keys(appState.cachedData.custom_delivery_costs)
                    .filter(customDeliveryCostId => {
                        return getIdFromUri(appState.cachedData.custom_delivery_costs[customDeliveryCostId].seller) === parseInt(authState.user.organizationId) &&
                        getIdFromUri(appState.cachedData.custom_delivery_costs[customDeliveryCostId].purchaser) === parseInt(props.match.params.idPurchaser)
                    })
                    .map(customDeliveryCostId => appState.cachedData.custom_delivery_costs[customDeliveryCostId])
            );
        }
    };

    const handleCreate = () => {
        props.history.push(`/custom_delivery_costs/create/${props.match.params.idPurchaser}`);
    };
    
    const handleEdit = customDeliveryCostId => {
        setInputs({...inputs, value: appState.cachedData.custom_delivery_costs[customDeliveryCostId].value})
        setCustomDeliveryCostId(customDeliveryCostId);
        setVisible(customDeliveryCostId);
    };

    const handleCancelEdit = () => {
        setInputs({...inputs, value: 0})
        setCustomDeliveryCostId(0);
        setVisible(0);
    };

    const handleDelete = () => {
        fetchInitAppState();
        fetch(
            `custom_delivery_costs/${customDeliveryCostId}`,
            {
                method: 'DELETE'
            }
        )
            .then(() => {
                fetchSuccessAppState();
                setCustomDeliveryCosts(customDeliveryCosts.filter(customDeliveryCost => customDeliveryCost.id !== parseInt(customDeliveryCostId)));
                updateCachedDataAppState('custom_delivery_costs', customDeliveryCostId, null);
                showMessageAppState('success', t('custom_delivery_cost_deleted'));
                handleCloseDialog();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleConfirm = () => {
        handleDelete();
    }

    const handleOpenDialog = id => {
        setCustomDeliveryCostId(id);
        if(visible !== 0) {
            setVisible(0);
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if(
        !authState.user.roles.includes('ROLE_SUPERADMIN') &&
        !authState.user.roles.includes('ROLE_ADMIN') &&
        !authState.user.roles.includes('ROLE_SUPPLIER') &&
        !authState.user.roles.includes('ROLE_RESELLER')
    ) {
        return (<Redirect to='/' />)
    } else {
        return (
            <div className={classes.root}>
                <MaterialList component="nav" className={classes.list} aria-label="contacts">
                {
                    customDeliveryCosts &&
                    customDeliveryCosts.length > 0 &&
                    customDeliveryCosts.map(customDeliveryCost => {
                        return (
                            <div key={customDeliveryCost.id}>
                                <ListItem>
                                    <ListItemText
                                        primary={customDeliveryCost.type === 0 ? t('standard') : t('express')}
                                        secondary={customDeliveryCost.value}
                                    />
                                    <ListItemSecondaryAction>
                                        <Box
                                            display={visible !== customDeliveryCost.id ? 'flex' : 'none'}
                                            flexDirection="row-reverse"
                                        >
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => handleOpenDialog(customDeliveryCost.id)}
                                                className={classes.icon}
                                                disabled={
                                                    appState.isLoading &&
                                                    appState.isRefreshing
                                                }
                                            >
                                                <Delete />
                                            </IconButton>
                                            <IconButton
                                                edge="end"
                                                aria-label="edit"
                                                onClick={() => handleEdit(customDeliveryCost.id)}
                                                className={classes.icon}
                                                disabled={
                                                    appState.isLoading &&
                                                    appState.isRefreshing
                                                }
                                            >
                                                <Edit />
                                            </IconButton>
                                        </Box>
                                        <Box
                                            display={visible === customDeliveryCost.id ? 'flex' : 'none'}
                                            flexDirection="row-reverse"
                                        >
                                            <IconButton
                                                edge="end"
                                                aria-label="cancel"
                                                onClick={() => handleCancelEdit()}
                                                className={classes.icon}
                                                disabled={
                                                    appState.isLoading &&
                                                    appState.isRefreshing
                                                }
                                            >
                                                <Clear />
                                            </IconButton>
                                            <IconButton
                                                edge="end"
                                                aria-label="submit"
                                                onClick={() => handleSubmit()}
                                                className={classes.icon}
                                                disabled={
                                                    appState.isLoading &&
                                                    appState.isRefreshing
                                                }
                                            >
                                                <Check />
                                            </IconButton>
                                            <TextField
                                                error={appState.isError}
                                                id={`value-${customDeliveryCost.id}`}
                                                name="value"
                                                label={t('new_value')}
                                                type="number"
                                                inputProps={{ step: "0.01" }}
                                                onChange={handleInputChange}
                                                value={inputs.value ? inputs.value : ''}
                                                className={classes.newValue}
                                                disabled={
                                                    appState.isLoading &&
                                                    appState.isRefreshing
                                                }
                                                required
                                            />
                                        </Box>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </div>
                        )
                    })
                }
                {
                    customDeliveryCosts &&
                    customDeliveryCosts.length === 0 &&
                    !appState.isError &&
                    (
                        appState.isLoading ||
                        appState.isRefreshing
                    ) &&
                    <div>{t('loading')}</div>
                }
                {
                    customDeliveryCosts &&
                    customDeliveryCosts.length === 0 &&
                    !appState.isError &&
                    !appState.isLoading &&
                    !appState.isRefreshing &&
                    <div>{t('no_result')}</div>
                }
                </MaterialList>
                {
                    customDeliveryCosts &&
                    customDeliveryCosts.length < 2 &&
                    (
                        authState.user.roles.includes('ROLE_SUPERADMIN') ||
                        authState.user.roles.includes('ROLE_SUPPLIER') ||
                        authState.user.roles.includes('ROLE_RESELLER')
                    ) && (
                        <Fab
                            color="primary"
                            aria-label="create"
                            className={classes.addFab}
                            onClick={handleCreate}
                        >
                            <Add />
                        </Fab>
                    )
                }
                <ConfirmDialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    onConfirm={handleConfirm}
                    title='confirm_delete'
                    content='confirm_custom_delivery_cost_delete_content'
                    {...props}
                />
            </div>
        )
    }
};

export default List;
import React, {useContext, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
    Button,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    List as MaterialList,
    ListItem,
    ListItemText,
    ListSubheader,
    makeStyles,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {AppContext, AuthContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {getIdFromUri, parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    cartItemOwnerSubHeader: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(-2),
        fontSize: '1.1rem',
        fontWeight: '600',
        fontStyle: 'italic',
    },
    cartItemsListItem: {
        marginLeft: theme.spacing(2),
    },
    amount: {
        alignSelf: 'flex-end',
    },
    actionButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    option: {
        color: theme.palette.text.customPrimary,
    },
    editFab: {
        position: 'absolute',
        bottom: '16px',
        right: '16px',
    },
}));

const Create = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        forceRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const classes = useStyles();
    const [cart, setCart] = useState({});
    const [organizationsList, setOrganizationsList] = useState([]);
    const [sortedCartItemsList, setSortedCartItemsList] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [subtotal, setSubtotal] = useState([]);
    const [total, setTotal] = useState(0);
    const [expressDelivery, setExpressDelivery] = useState({});
    const { fetch } = useDataAccess();

    const handleCreateOrder = () => {
        fetchInitAppState();
        fetch(
            `create_order`,
            {
                method: 'POST',
                body: {
                    idCart: props.match.params.cartId,
                    orderReference: inputs.orderReference,
                    billingAddress: inputs.billingAddress,
                    shippingAddress: inputs.shippingAddress,
                    expressDelivery: JSON.stringify(expressDelivery)
                }
            }
        )
            .then(response => {
                return response.json();
            })
            .then(json => {
                fetchSuccessAppState();
                forceRefreshResourceAppState('orders');
                if(
                    authState &&
                    authState.user &&
                    authState.user.cartId
                ) {
                    forceRefreshResourceAppState(`carts/${authState.user.cartId}`);
                }
                showMessageAppState('success', t(json.message));
                props.history.push('/my_orders');
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };
    const { inputs, handleInputChange, handleSubmit, setInputs } = useForm(handleCreateOrder);

    useEffect(() => {
        setPageInfos('new_order', false);
        setCartFromCache();
        setAddressesFromCache();
        if(
            authState &&
            authState.user &&
            authState.user.organizationId &&
            appState &&
            appState.cachedData &&
            appState.cachedData.organization &&
            appState.cachedData.organization[authState.user.organizationId] &&
            appState.cachedData.organization[authState.user.organizationId].mainAddress
        ) {
            const mainAddressId = getIdFromUri(appState.cachedData.organization[authState.user.organizationId].mainAddress);
            setInputs(() => ({
                billingAddress: mainAddressId,
                shippingAddress: mainAddressId,
            }));
        }
        if(
            authState &&
            authState.user &&
            authState.user.cartId
        ) {
            addRefreshResourceAppState(`carts/${authState.user.cartId}`);
        }
        addRefreshResourceAppState('addresses');
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCartFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.carts]);

    useEffect(() => {
        setAddressesFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.addresses]);

    useEffect(() => {
        if(cart.cartItems && cart.cartItems.length > 0)
            sortCartItemsList(cart.cartItems);
        // eslint-disable-next-line
    }, [cart]);

    useEffect(() => {
        organizationsList.map(currentOrganization => {
            return setExpressDelivery(prevExpressDelivery => {
                return { ...prevExpressDelivery, [currentOrganization.id]: 0 }
            });
        });
        // eslint-disable-next-line
    }, [organizationsList]);

    useEffect(() => {
        computeTotalAmount();
        // eslint-disable-next-line
    }, [organizationsList, sortedCartItemsList, expressDelivery]);

    const setCartFromCache = () => {
        if(
            authState &&
            authState.user &&
            authState.user.cartId &&
            appState.cachedData &&
            appState.cachedData.carts &&
            appState.cachedData.carts[authState.user.cartId]
        ) {
            setCart(appState.cachedData.carts[authState.user.cartId]);
        }
    };

    const setAddressesFromCache = () => {
        if(
            appState.cachedData &&
            appState.cachedData.addresses
        ) {
            setAddresses(
                Object.keys(appState.cachedData.addresses)
                    .map(addressId => appState.cachedData.addresses[addressId])
            );
        }
    };

    const updateOrganizationsList = newOrganizationslist => {
        setOrganizationsList(newOrganizationslist);
    };

    const updateSortedCartItemsList = newSortedCartItemslist => {
        setSortedCartItemsList(newSortedCartItemslist);
    };

    const sortCartItemsList = paramCartItemsList => {
        let tmpCartItemsOwners = [];
        let tmpOrganizationsList = [];
        let tmpSortedCartItemsList = [];
        if(null !== paramCartItemsList) {
            Object.keys(paramCartItemsList).map(key => {
                if(!tmpCartItemsOwners.includes(paramCartItemsList[key].productOwner.id)) {
                    tmpCartItemsOwners.push(paramCartItemsList[key].productOwner.id);
                    return tmpOrganizationsList.push({
                        'id': paramCartItemsList[key].productOwner.id,
                        'name': paramCartItemsList[key].productOwner.name,
                        'deliveryStandard': paramCartItemsList[key].productOwner.customDeliveryCost ? paramCartItemsList[key].productOwner.customDeliveryCost[0] : 0,
                        'deliveryExpress': paramCartItemsList[key].productOwner.customDeliveryCost ? paramCartItemsList[key].productOwner.customDeliveryCost[1] : 0,
                    });
                } else {
                    return false;
                }
            });
            tmpCartItemsOwners.map(owner => {
                return Object.keys(paramCartItemsList).map(key => {
                    if(paramCartItemsList[key].productOwner.id === owner) {
                        return tmpSortedCartItemsList.push({'owner': paramCartItemsList[key].productOwner.id, 'cartItem': paramCartItemsList[key]});
                    } else {
                        return false;
                    }
                });
            });
        }
        updateOrganizationsList(tmpOrganizationsList);
        updateSortedCartItemsList(tmpSortedCartItemsList);
    };

    const computeTotalAmount = () => {
        setSubtotal(() => {
            let tmpSubtotal = [];
            if(organizationsList.length > 0 && sortedCartItemsList.length > 0) {
                organizationsList.map(currentOrganization => {
                    return tmpSubtotal[currentOrganization.id] = expressDelivery[currentOrganization.id] === 'express' ?
                        currentOrganization.deliveryExpress :
                        currentOrganization.deliveryStandard;
                });
                sortedCartItemsList.map(currentCartItem => {
                    return tmpSubtotal[currentCartItem.owner] += (currentCartItem.cartItem.price * currentCartItem.cartItem.quantity);
                });
            }
            return tmpSubtotal;
        });
        if(cart && cart.total) {
            setTotal(() => {
                let tmpTotal = cart.total;
                if(organizationsList.length > 0) {
                    organizationsList.map(currentOrganization => {
                        return tmpTotal += expressDelivery[currentOrganization.id] === 'express' ?
                            currentOrganization.deliveryExpress :
                            currentOrganization.deliveryStandard;
                    });
                }
                return tmpTotal;
            });
        }
    };

    const handleCheckExpressDelivery = paramOrganizationId => event => {
        setExpressDelivery({
            ...expressDelivery,
            [paramOrganizationId]: event.target.value
        });
    };

    if(
        !authState.user.roles.includes('ROLE_SUPERADMIN') &&
        !authState.user.roles.includes('ROLE_RESELLER') &&
        !authState.user.roles.includes('ROLE_CUSTOMER')
    ) {
        return (<Redirect to='/' />)
    } else if(
        cart &&
        cart.cartItems &&
        cart.cartItems.length <= 0
    ) {
        return (<Redirect to='/my_orders' />)
    } else {
        return (
            <Container>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    className={classes.root}
                >
                    {
                        appState.isError &&
                        appState.error &&
                        (
                            <Grid item xs={12} className="text-center">
                                <div className="alert alert-danger" role="alert">
                                    <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
                                    {appState.error}
                                </div>
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit} className="container">
                            <FormGroup>
                                <FormControl className={classes.formControl}>
                                    <MaterialList component="nav" className={classes.root} aria-label={t('cart_items')}>
                                    {
                                        organizationsList.length > 0 &&
                                        organizationsList.map(organization => {
                                            return (
                                                <div key={organization.id}>
                                                    <ListSubheader  className={classes.cartItemOwnerSubHeader}>
                                                        {organization.name}
                                                    </ListSubheader>
                                                    {
                                                        sortedCartItemsList.length > 0 &&
                                                        sortedCartItemsList
                                                            .filter(currentItem => currentItem.owner === organization.id)
                                                            .map(currentItem => {
                                                                return (
                                                                    <div key={currentItem.cartItem['@id']}>
                                                                        <ListItem>
                                                                            <ListItemText
                                                                                primary={currentItem.cartItem.product.name}
                                                                                secondary={
                                                                                    <span>
                                                                                        <span>{t('unit_price')} : {currentItem.cartItem.price} €</span><br />
                                                                                        <span>{t('quantity')} : {currentItem.cartItem.quantity}</span><br />
                                                                                        <span>{t('subtotal')} : {currentItem.cartItem.price * currentItem.cartItem.quantity} €</span>
                                                                                    </span>
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                        <Divider />
                                                                    </div>
                                                                )
                                                            })
                                                    }
                                                    {
                                                        expressDelivery &&
                                                        <RadioGroup
                                                            aria-label={t('delivery_type')}
                                                            name="delivery_type"
                                                            defaultValue="standard"
                                                            onChange={handleCheckExpressDelivery(organization.id)}
                                                        >
                                                            <FormControlLabel
                                                                control={<Radio/>}
                                                                value="standard"
                                                                label={`${t('standard')} (${organization.deliveryStandard} ${t('currency')})`}
                                                                disabled={organization.deliveryStandard === 0}
                                                            />
                                                            <FormControlLabel
                                                                control={<Radio/>}
                                                                value="express"
                                                                label={`${t('express')} (${organization.deliveryExpress} ${t('currency')})`}
                                                                disabled={organization.deliveryExpress === 0}
                                                            />
                                                        </RadioGroup>
                                                    }
                                                    <div className={classes.amount}>
                                                        {t('subtotal')} {organization.name} : {subtotal[organization.id] ? subtotal[organization.id] : 0} {t('currency')}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        !appState.isLoading &&
                                        !appState.isRefreshing &&
                                        cart &&
                                        cart.cartItems &&
                                        cart.cartItems.length === 0 &&
                                        <div>{t('no_result')}</div>
                                    }
                                    </MaterialList>
                                    {
                                        cart &&
                                        <Typography className={classes.amount}>
                                            {`${t('total_amount')} : ${total} ${t('currency')}`}
                                        </Typography>
                                    }
                                    {
                                        !cart &&
                                        !appState.isLoading &&
                                        <div>{t('empty_cart')}</div>
                                    }
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        margin="normal"
                                        error={appState.isError}
                                        id="orderReference"
                                        name="orderReference"
                                        label={t('order_reference')}
                                        type="text"
                                        onChange={handleInputChange}
                                        value={inputs.orderReference ? inputs.orderReference : ''}
                                        disabled={
                                            appState.isLoading &&
                                            appState.isRefreshing
                                        }
                                        required
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="billingAddress">
                                        {t('billing_address')}
                                    </InputLabel>
                                    <Select
                                        native
                                        required
                                        disabled={
                                            appState.isLoading &&
                                            appState.isRefreshing
                                        }
                                        value={inputs.billingAddress || ''}
                                        onChange={handleInputChange}
                                        inputProps={{
                                            name: 'billingAddress',
                                            id: 'billingAddress',
                                        }}
                                    >
                                        <option value='' />
                                        {
                                            addresses &&
                                            addresses.length > 0 &&
                                            addresses.map(address => {
                                                return (
                                                    <option
                                                        key={address.id}
                                                        value={address.id}
                                                        className={classes.option}
                                                    >
                                                        {address.line1} {address.postalCode} {address.city}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="shippingAddress">
                                        {t('shipping_address')}
                                    </InputLabel>
                                    <Select
                                        native
                                        required
                                        disabled={
                                            appState.isLoading &&
                                            appState.isRefreshing
                                        }
                                        value={inputs.shippingAddress || ''}
                                        onChange={handleInputChange}
                                        inputProps={{
                                            name: 'shippingAddress',
                                            id: 'shippingAddress',
                                        }}
                                    >
                                        <option value='' />
                                        {
                                            addresses &&
                                            addresses.length > 0 &&
                                            addresses.map(address => {
                                                return (
                                                    <option
                                                        key={address.id}
                                                        value={address.id}
                                                        className={classes.option}
                                                    >
                                                        {address.line1} {address.postalCode} {address.city}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.actionButton}
                                        disabled={
                                            appState.isLoading ||
                                            appState.isRefreshing ||
                                            (
                                                cart && cart.cartItems &&
                                                cart.cartItems.length <= 0
                                            ) || (
                                                addresses &&
                                                addresses.length <= 0
                                            ) || (
                                                !inputs.orderReference ||
                                                !inputs.billingAddress ||
                                                !inputs.shippingAddress
                                            )
                                        }
                                    >
                                        {t('create_order')}
                                    </Button>
                                </FormControl>
                            </FormGroup>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        )
    }
};

export default Create;
import React, {useRef, useState} from "react";
import ReactToPrint from 'react-to-print';
import {useTranslation} from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    List as MaterialList,
    ListItem,
    ListItemText,
    makeStyles,
    Tab,
    Tabs,
    Typography
} from "@material-ui/core";
// import {
//     AppContext,
//     AuthContext
// } from "../../context";

const useStyles = makeStyles(theme => ({
    list: {
        textAlign: 'center',
    },
    button: {
        margin: theme.spacing(2),
        color: theme.palette.text.customPrimary,
    },
    textCenter: {
        color: theme.palette.text.customPrimary,
        textAlign: 'center',
    },
    text: {
        color: theme.palette.text.customPrimary,
    },
}));

const PrintQuoteDialog = props => {
    const { t } = useTranslation();
    // const { authState } = useContext(AuthContext);
    // const { appState } = useContext(AppContext);
    const classes = useStyles();
    const componentRef = useRef();
    const [organizationId, setOrganizationId] = useState(0);
  
    const handleChange = (event, newOrganizationId) => {
        setOrganizationId(newOrganizationId);
    };

    const handleCancel = () => {
        props.onClose();
    };

    const handleClose = () => {
        props.onClose();
    };

    return (
        <Dialog onClose={() => handleClose} aria-labelledby="dialog-title" open={props.open}>
            <DialogTitle id="dialog-title" className={classes.textCenter}>{t(props.title)}</DialogTitle>
            <DialogContent>
                <Tabs
                    value={organizationId}
                    onChange={handleChange}
                    classes={{
                        indicator: {
                            color: classes.text.color
                        }
                    }}
                    centered
                >
                    {
                        props.organizations.length > 0 &&
                        props.organizations.map(organization => {
                            return (
                                <Tab label={organization.name} />
                            )
                        })
                    }
                </Tabs>
                <DialogContentText
                    id="alert-dialog-slide-description"
                    className={classes.textCenter}
                    ref={componentRef}
                >
                {
                    props.organizations.length > 0 &&
                    props.organizations.map(organization => {
                        return (
                            <div key={organization.id}>
                                <Typography  className={classes.textCenter}>
                                    {organization.name}
                                </Typography>
                                <MaterialList component="nav" className={classes.list} aria-label={t('cart_items')}>
                                {
                                    props.cartItems.length > 0 &&
                                    props.cartItems
                                        .filter(currentItem => currentItem.owner === organization.id)
                                        .map(currentItem => {
                                            return (
                                                <div key={currentItem.cartItem['@id']}>
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={currentItem.cartItem.product.name}
                                                            secondary={
                                                                <span>
                                                                    <span>{t('unit_price')} : {currentItem.cartItem.price} €</span><br />
                                                                    <span>{t('quantity')} : {currentItem.cartItem.quantity}</span><br />
                                                                    <span>{t('subtotal')} : {currentItem.cartItem.price * currentItem.cartItem.quantity} €</span>
                                                                </span>
                                                            }
                                                            classes={{
                                                                primary: classes.text,
                                                                secondary: classes.text
                                                            }}
                                                        />
                                                    </ListItem>
                                                    <Divider />
                                                </div>
                                            )
                                        })
                                }
                                </MaterialList>
                                <span className={classes.text}>
                                    {t('subtotal')} {organization.name} : {props.subtotal[organization.id] ? props.subtotal[organization.id] : 0} {t('currency')}
                                </span>
                            </div>
                        )
                    })
                }
                {
                    props.total &&
                    <Typography className={classes.text}>
                        {`${t('total_amount')} : ${props.total} ${t('currency')}`}
                    </Typography>
                }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary" className={classes.button}>
                    {t('cancel')}
                </Button>
                <ReactToPrint
                    trigger={() => <Button color="primary" className={classes.button}>{t('print')}</Button>}
                    content={() => componentRef.current}
                />
            </DialogActions>
        </Dialog>
    );
};

export default PrintQuoteDialog;
import React from 'react';
import {makeStyles} from "@material-ui/core";
import LogoPng from './LogoPng.png';
import LogoMenuPng from './LogoMenuPng.png';

const useStyles = makeStyles(() => ({
    logoContainer: {
        margin: '20px',
        textAlign: 'center',
    },
    logo: {
        maxWidth: '70%',
    },
}));

const Logo = props => {
    const classes = useStyles();

    return (
        <div className={classes.logoContainer}>
            <img
                src={props.type === 'menu' ? LogoMenuPng : LogoPng}
                alt='logo'
                className={classes.logo}
            />
        </div>
    )
};

export default Logo;
import React from 'react';
import {Route} from 'react-router-dom';
import {Login, Register, ConfirmUser, CreatePassword, ResetPassword} from '../hooks/auth';
import {Wrapper} from "../hooks/common";

export default [
  <Route path="/login" component={Login} exact key="login" />,
  <Route path="/register" component={Register} exact key="register" />,
  <Route path="/confirm-user/:token" component={ConfirmUser} exact key="confirm-user" />,
  <Route path="/create-password/:token" component={CreatePassword} exact key="create-password" />,
  <Route path="/reset-password/" component={props => <Wrapper component={ResetPassword} {...props} />} exact key="reset-password" />
];
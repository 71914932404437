import * as ACTION_TYPES from '../actions/action_types';

export const initialState = {
    isLoading: false,
    isRefreshing: false,
    isAborted: false,
    isError: false,
    showMessage: false,
    cachedData: {
        addresses: null,
        carts: null,
        catalogs: null,
        custom_delivery_costs: null,
        custom_margins: null,
        orders: null,
        organization: null,
        customers: null,
        resellers: null,
        suppliers: null,
        organization_requests: null,
        products: null,
        productsPerCatalog: null,
        images: null,
        users: null,
    },
    refreshResources: null,
    refreshView: false,
    error: null,
    variant: 'success',
    message: '',
    pageTitle: '',
    isMainPage: true,
};

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.APP_STATE_INIT:
            return {
                ...state,
                isLoading: false,
                isRefreshing: false,
                isAborted: false,
                isError: false,
                showMessage: false,
                cachedData: {
                    addresses: null,
                    carts: null,
                    catalogs: null,
                    custom_delivery_costs: null,
                    custom_margins: null,
                    orders: null,
                    organization: null,
                    customers: null,
                    resellers: null,
                    suppliers: null,
                    organization_requests: null,
                    products: null,
                    productsPerCatalog: null,
                    images: null,
                    users: null,
                },
                refreshResources: null,
                refreshView: false,
                error: null,
                variant: 'success',
                message: '',
                pageTitle: '',
                isMainPage: true,
            };
        case ACTION_TYPES.APP_STATE_INIT_CACHED_DATA:
            return {
                ...state,
                cachedData: {
                    addresses: null,
                    carts: null,
                    catalogs: null,
                    custom_delivery_costs: null,
                    custom_margins: null,
                    orders: null,
                    organization: null,
                    customers: null,
                    resellers: null,
                    suppliers: null,
                    organization_requests: null,
                    products: null,
                    productsPerCatalog: null,
                    images: null,
                    users: null,
                },
                refreshResources: null,
            };
        case ACTION_TYPES.APP_STATE_FETCH_INIT:
            return {
                ...state,
                isLoading: true,
                isError: false,
                isAborted: false,
                error: null,
            };
        case ACTION_TYPES.APP_STATE_FETCH_ABORT:
            return {
                ...state,
                isLoading: false,
                isRefreshing: false,
                isAborted: true,
                isError: false,
                error: null,
            };
        case ACTION_TYPES.APP_STATE_FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isRefreshing: false,
                isAborted: false,
                isError: false,
                error: null,
            };
        case ACTION_TYPES.APP_STATE_UPDATE_CACHED_DATA:
            if(action.data !== null ) {
                return {
                    ...state,
                    cachedData: {
                        ...state.cachedData,
                        [action.key]: {...state.cachedData[action.key], [action.index]: action.data}
                    }
                }
            } else {
                const tmpActionKey = state.cachedData[action.key];
                delete tmpActionKey[action.index];
                return {
                    ...state,
                    cachedData: {
                        ...state.cachedData,
                        [action.key]: {...tmpActionKey}
                    }
                }
            }
        case ACTION_TYPES.APP_STATE_FETCH_ERROR:
            return {
                ...state,
                isLoading: false,
                isRefreshing: false,
                isAborted: false,
                isError: true,
                error: action.error,
            };
        case ACTION_TYPES.APP_STATE_REFRESH_ADD_RESOURCE:
            if(
                state.refreshResources &&
                state.refreshResources[action.resource] &&
                (
                    state.refreshResources[action.resource].status ||
                    state.refreshResources[action.resource].lastUpdateTime > Date.now() - 60000
                )
            ) {
                return state;
            }
            return {
                ...state,
                refreshResources: {
                    ...state.refreshResources,
                    [action.resource]: {
                        lastUpdateTime: null,
                        status: true,
                    }
                }
            };
        case ACTION_TYPES.APP_STATE_FORCE_REFRESH_RESOURCE:
            return {
                ...state,
                refreshResources: {
                    ...state.refreshResources,
                    [action.resource]: {
                        lastUpdateTime: null,
                        status: true,
                    }
                }
            };
        case ACTION_TYPES.APP_STATE_REFRESH_REMOVE_RESOURCE:
            if(
                !state.refreshResources ||
                (
                    state.refreshResources[action.resource] &&
                    !state.refreshResources[action.resource].status
                )
            ) {
                return state;
            }
            return {
                ...state,
                refreshResources: {
                    ...state.refreshResources,
                    [action.resource]: {
                        lastUpdateTime: Date.now(),
                        status: false,
                    }
                },
            };
        case ACTION_TYPES.APP_STATE_REFRESH_STATUS:
            return {
                ...state,
                isRefreshing: action.value,
            };
        case ACTION_TYPES.APP_STATE_REFRESH_VIEW:
            return {
                ...state,
                isLoading: action.value,
                refreshView: action.value,
            };
        case ACTION_TYPES.APP_STATE_INIT_MESSAGE:
            return {
                ...state,
                showMessage: false,
                variant: 'success',
                message: '',
            };
        case ACTION_TYPES.APP_STATE_SHOW_MESSAGE:
            return {
                ...state,
                showMessage: true,
                variant: action.variant,
                message: action.message
            };
        case ACTION_TYPES.APP_STATE_SET_PAGE_INFOS:
            return {
                ...state,
                pageTitle: action.pageTitle,
                isMainPage: action.isMainPage,
                isLoading: false,
                isRefreshing: false,
                isAborted: false,
                isError: false,
                error: null,
            };
        default:
            return state;
    }
};
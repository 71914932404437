import React, {useRef} from "react";
import ReactToPrint from 'react-to-print';
import QRCode from "qrcode.react";
import {useTranslation} from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(2),
        color: theme.palette.text.customPrimary,
    },
    input: {
        display: 'none',
        color: theme.palette.text.customPrimary,
    },
    text: {
        color: theme.palette.text.customPrimary,
        textAlign: 'center',
    },
}));

const PrintQRCodeDialog = props => {
    const { t } = useTranslation();
    const classes = useStyles();
    const componentRef = useRef();

    const handleCancel = () => {
        props.onClose();
    };

    const handleClose = () => {
        props.onClose();
    };

    return (
        <Dialog onClose={() => handleClose} aria-labelledby="dialog-title" open={props.open}>
            <DialogTitle id="dialog-title" className={classes.text}>{t(props.title)}</DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-slide-description"
                    className={classes.text}
                    ref={componentRef}
                >
                    <QRCode value={`${process.env.REACT_APP_APP_ENTRYPOINT}catalogs/show_product/${props.product.id}`} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary" className={classes.button}>
                    {t('cancel')}
                </Button>
                <ReactToPrint
                    trigger={() => <Button color="primary" className={classes.button}>{t('print')}</Button>}
                    content={() => componentRef.current}
                />
            </DialogActions>
        </Dialog>
    );
};

export default PrintQRCodeDialog;
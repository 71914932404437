import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Container, FormControl, FormGroup, Grid, makeStyles, TextField} from "@material-ui/core";
import {AppContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const Update = props => {
    const { t } = useTranslation();
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        setPageInfos,
        showMessageAppState
    } = useContext(AppContext);
    const classes = useStyles();
    const [catalog, setCatalog] = useState({});
    const {fetch} = useDataAccess();
    const handleUpdate = () => {
        fetchInitAppState();
        fetch(
            `catalogs/${props.match.params.id}`,
            {
                method: 'PUT',
                body: {
                    name: inputs.name
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                showMessageAppState('success', t('catalog_updated'));
                props.history.goBack();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };
    const {inputs, handleInputChange, handleSubmit, setInputs} = useForm(handleUpdate);

    useEffect(() => {
        setPageInfos('edit_catalog', false);
        setCatalogFromCache();
        addRefreshResourceAppState(`catalogs/${props.match.params.id}`);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setInputs(inputs => ({...inputs, ...catalog}));
        // eslint-disable-next-line
    }, [catalog]);

    const setCatalogFromCache = () => {
        if(
            appState.cachedData &&
            appState.cachedData.catalogs &&
            appState.cachedData.catalogs[props.match.params.id]
        ) {
            setCatalog(appState.cachedData.catalogs[props.match.params.id]);
        } else {
            setCatalog(null);
        }
    };

    return (
        <Container>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
            >
                {
                    appState.isError &&
                    appState.error &&
                    (
                        <Grid item xs={12} className="text-center">
                            <div className="alert alert-danger" role="alert">
                                <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
                                {appState.error}
                            </div>
                        </Grid>
                    )
                }
                <form onSubmit={handleSubmit} className="container">
                    <FormGroup>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="name"
                                name="name"
                                label={t('catalog_name')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.name ? inputs.name : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            >
                                {t('update')}
                            </Button>
                        </FormControl>
                    </FormGroup>
                </form>
            </Grid>
        </Container>
    )
};

export default Update;
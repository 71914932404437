export const AUTH_REDUCER_INIT = 'AUTH_REDUCER_INIT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'AUTH_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS';
export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS';
export const AUTH_REFRESH = 'AUTH_REFRESH';

export const APP_STATE_INIT = 'APP_STATE_INIT';
export const APP_STATE_INIT_CACHED_DATA = 'APP_STATE_INIT_GLOBAL_DATA';
export const APP_STATE_FETCH_INIT = 'APP_STATE_FETCH_INIT';
export const APP_STATE_FETCH_ABORT = 'APP_STATE_FETCH_ABORT';
export const APP_STATE_FETCH_SUCCESS = 'APP_STATE_FETCH_SUCCESS';
export const APP_STATE_FETCH_ERROR = 'APP_STATE_FETCH_ERROR';
export const APP_STATE_UPDATE_CACHED_DATA = 'APP_STATE_UPDATE_CACHED_DATA';
export const APP_STATE_REFRESH_STATUS = 'APP_STATE_REFRESH_STATUS';
export const APP_STATE_REFRESH_ADD_RESOURCE = 'APP_STATE_REFRESH_ADD_RESOURCE';
export const APP_STATE_FORCE_REFRESH_RESOURCE = 'APP_STATE_FORCE_REFRESH_RESOURCE';
export const APP_STATE_REFRESH_REMOVE_RESOURCE = 'APP_STATE_REFRESH_REMOVE_RESOURCE';
export const APP_STATE_REFRESH_VIEW = 'APP_STATE_REFRESH_VIEW';
export const APP_STATE_INIT_MESSAGE = 'APP_STATE_INIT_MESSAGE';
export const APP_STATE_SHOW_MESSAGE = 'APP_STATE_SHOW_MESSAGE';
export const APP_STATE_SET_PAGE_INFOS = 'APP_STATE_SET_PAGE_INFOS';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Divider,
    IconButton,
    InputBase,
    List as MaterialList,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Paper
} from "@material-ui/core";
import {Delete, Edit, Search} from '@material-ui/icons';
import {AppContext, AuthContext} from '../../context';
import {useDataAccess} from '../custom_hooks';
import {ConfirmDialog} from "../dialog";
import {parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    list: {
        marginBottom: theme.spacing(5),
    },
    paper: {
        maxWidth: 500,
        margin: 'auto',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    input: {
        width: '80%',
        color: theme.palette.text.customPrimary,
    },
    addFab: {
        zIndex: '1000',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    linkButton: {
        textDecoration: 'none !important',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    icon: {
        color: theme.palette.primary.main,
    },
}));

const List = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchErrorAppState,
        updateCachedDataAppState,
        addRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const {fetch} = useDataAccess();
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [orders, setOrders] = useState([]);
    const [orderId, setOrderId] = useState(0);
    const [filteredOrders, setFilteredOrders] = useState({});

    useEffect(() => {
        setOrdersFromCache();
        addRefreshResourceAppState('orders');
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setFilteredOrders(orders);
    }, [orders]);

    useEffect(() => {
        setOrdersFromCache();
        if(
            authState &&
            authState.user &&
            (
                authState.user.roles.includes("ROLE_RESELLER") ||
                authState.user.roles.includes("ROLE_CUSTOMER")
            )
        ) {
            addRefreshResourceAppState(`carts/${authState.user.cartId}`);
        }
        // eslint-disable-next-line
    }, [appState.cachedData.orders]);

    const setOrdersFromCache = () => {
        switch(props.type) {
            case 'ordered':
                setPageInfos('my_orders', true);
                if(appState.cachedData && appState.cachedData.orders) {
                    setOrders(
                        Object.keys(appState.cachedData.orders)
                            .filter(orderId => appState.cachedData.orders[orderId].purchaser.id === authState.user.organizationId)
                            .map(orderId => appState.cachedData.orders[orderId])
                    );
                } else {
                    setOrders([]);
                }
                break;
            case 'received':
                setPageInfos('received_orders', true);
                if(appState.cachedData && appState.cachedData.orders) {
                    setOrders(
                        Object.keys(appState.cachedData.orders)
                            .filter(orderId => appState.cachedData.orders[orderId].seller.id === authState.user.organizationId)
                            .map(orderId => appState.cachedData.orders[orderId])
                    );
                } else {
                    setOrders([]);
                }
                break;
            default:
                setPageInfos('orders', true);
                if(appState.cachedData && appState.cachedData.orders) {
                    setOrders(
                        Object.keys(appState.cachedData.orders)
                            .map(orderId => appState.cachedData.orders[orderId])
                    );
                } else {
                    setOrders([]);
                }
        }
    };

    const handleClick = paramOrderId => {
        props.history.push(`/orders/show/${paramOrderId}`);
    };

    const handleEdit = paramOrderId => {
        props.history.push(`/orders/edit/${paramOrderId}`);
    };

    const handleDelete = () => {
        fetchInitAppState();
        fetch(
            `orders/${orderId}`,
            {
                method: 'DELETE'
            }
        )
            .then(() => {
                setOrders(orders.filter(order => order.id !== parseInt(orderId)));
                updateCachedDataAppState('orders', orderId, null);
                showMessageAppState('success', t('order_deleted'));
                handleCloseDialog();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleConfirm = () => {
        handleDelete();
    };

    const handleOpenDialog = paramOrderId => {
        setOrderId(paramOrderId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const filterOrdersList = event => {
        event.persist();
        let tmpFilteredOrdersList = orders.filter(order => order.reference.toLowerCase().includes(event.target.value.toLowerCase()));
        setFilteredOrders(tmpFilteredOrdersList);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <IconButton disabled aria-label="search">
                    <Search />
                </IconButton>
                <InputBase
                    className={classes.input}
                    placeholder={t('filter')}
                    inputProps={{ 'aria-label': 'filter orders list' }}
                    onChange={filterOrdersList}
                />
            </Paper>
            <MaterialList component="nav" className={classes.list} aria-label={t('orders')}>
                {
                    filteredOrders &&
                    filteredOrders.length > 0 &&
                    filteredOrders.map(order => {
                        let tmpOrderStatus;
                        switch(order.status) {
                            case 1:
                                tmpOrderStatus = t('order_confirmed');
                                break;
                            case 2:
                                tmpOrderStatus = t('preparing_order');
                                break;
                            case 3:
                                tmpOrderStatus = t('order_sent');
                                break;
                            default:
                                tmpOrderStatus = t('order_created');
                        }
                        return (
                            <div key={order.id}>
                                <ListItem
                                    button
                                    onClick={() => handleClick(order.id)}
                                >
                                    <ListItemText
                                        primary={
                                            <span>
                                                {
                                                    order.purchaser.id === authState.user.organizationId &&
                                                    <span>{`${t('seller')} : ${order.seller.name}`}</span>
                                                }
                                                {
                                                    order.seller.id === authState.user.organizationId &&
                                                    <span>{`${t('purchaser')} : ${order.purchaser.name}`}</span>
                                                }
                                                <span>
                                                <br/>
                                                    {`${t('reference')} : ${order.reference}`}
                                                </span>
                                                <br/>
                                                <span>
                                                    {`${t('total_amount')} : ${order.total} €`}
                                                </span>
                                            </span>
                                        }
                                        secondary={
                                            <span>
                                                <span>
                                                    {`${t('order_status')} : ${tmpOrderStatus}`}
                                                </span>
                                                <br/>
                                                <span>
                                                    {`${t('products_number')} : ${order.orderItems.length}`}
                                                </span>
                                                <br/>
                                            </span>
                                        }
                                    />
                                    {
                                        props.type === 'ordered' &&
                                        order.status === 0 &&
                                        authState.user.organizationId &&
                                        (
                                            authState.user.roles.includes('ROLE_SUPERADMIN') ||
                                            authState.user.organizationId === order.purchaser.id
                                        ) &&
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                aria-label="edit"
                                                disabled={
                                                    appState.isLoading &&
                                                    appState.isRefreshing
                                                }
                                                onClick={() => handleEdit(order.id)}
                                                className={classes.icon}
                                            >
                                                <Edit />
                                            </IconButton>
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                disabled={
                                                    appState.isLoading ||
                                                    appState.isRefreshing ||
                                                    order.status >= 2
                                                }
                                                onClick={() => handleOpenDialog(order.id)}
                                                className={classes.icon}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                                <Divider />
                            </div>
                        )
                    })
                }
                {
                    orders &&
                    orders.length === 0 &&
                    !appState.isError &&
                    (
                        appState.isLoading ||
                        appState.isRefreshing
                    ) &&
                    <div>{t('loading')}</div>
                }
                {
                    orders &&
                    orders.length <= 0 &&
                    !appState.isError &&
                    !appState.isLoading &&
                    !appState.isRefreshing &&
                    <div>{t('no_result')}</div>
                }
            </MaterialList>
            <ConfirmDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirm}
                title='confirm_delete'
                content='confirm_order_delete_content'
                {...props}
            />
        </div>
    )
};

export default List;
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Button,
    Container,
    FormControl,
    FormGroup,
    Grid,
    InputLabel,
    makeStyles,
    NativeSelect,
    TextField
} from "@material-ui/core";
import {AppContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {getIdFromUri, parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    option: {
        color: theme.palette.text.customPrimary,
    },
}));

const Create = props => {
    const { t } = useTranslation();
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        forceRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const classes = useStyles();
    const handleCreate = () => {
        fetchInitAppState();
        fetch(
            'custom_margins',
            {
                method: 'POST',
                body: {
                    product: `/api/products/${props.match.params.id}`,
                    organization: `/api/organizations/${inputs.organization}`,
                    value: parseFloat(inputs.value)
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                forceRefreshResourceAppState(`custom_margins?product=${props.match.params.id}`);
                showMessageAppState('success', t('custom_margin_added'));
                props.history.goBack();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };
    const {inputs, handleInputChange, handleSubmit} = useForm(handleCreate);
    const {fetch} = useDataAccess();
    const [grantedOrganizations, setGrantedOrganizations] = useState([]);

    useEffect(() => {
        setPageInfos('new_custom_margin', false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        initGrantedOrganizations();
        addRefreshResourceAppState(`custom_margins?product=${props.match.params.id}`);
        // eslint-disable-next-line
    }, [props.match.params.id]);

    useEffect(() => {
        initGrantedOrganizations();
        // eslint-disable-next-line
    }, [appState.cachedData.custom_margins]);

    const initGrantedOrganizations = () => {
        let tmpGrantedOrganizations = [];
        if(
            appState &&
            appState.cachedData &&
            appState.cachedData.products &&
            appState.cachedData.products[props.match.params.id] &&
            appState.cachedData.products[props.match.params.id].grantedOrganizations &&
            appState.cachedData.products[props.match.params.id].grantedOrganizations.length > 0
        ) {
            tmpGrantedOrganizations = appState.cachedData.products[props.match.params.id].grantedOrganizations;
            if(appState.cachedData.custom_margins) {
                Object.keys(appState.cachedData.custom_margins).map(customMarginId => {
                    if(getIdFromUri(appState.cachedData.custom_margins[customMarginId].product) === parseInt(props.match.params.id)) {
                        return tmpGrantedOrganizations = tmpGrantedOrganizations.filter(organization => organization.id !== getIdFromUri(appState.cachedData.custom_margins[customMarginId].organization));
                    }
                    return false;
                });
            }
        }
        setGrantedOrganizations(tmpGrantedOrganizations);
    };

    return (
        <Container>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
            >
                {
                    appState.isError &&
                    appState.error &&
                    (
                        <Grid item xs={12} className="text-center">
                            <div className="alert alert-danger" role="alert">
                                <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
                                {t(appState.error.toString().slice(7))}
                            </div>
                        </Grid>
                    )
                }
                <form onSubmit={handleSubmit} className="container">
                    <FormGroup>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="organization">
                                {t('organization')}
                            </InputLabel>
                            <NativeSelect
                                required
                                disabled={
                                    appState.isLoading ||
                                    appState.isRefreshing ||
                                    (
                                        grantedOrganizations &&
                                        grantedOrganizations.length === 0
                                    )
                                }
                                value={inputs.organization || ''}
                                onChange={handleInputChange}
                                inputProps={{
                                    name: 'organization',
                                    id: 'organization',
                                }}
                            >
                                <option value='' />
                                {
                                    grantedOrganizations &&
                                    grantedOrganizations.length > 0 &&
                                    grantedOrganizations.map(organization => {
                                        return (
                                            <option
                                                value={organization.id}
                                                key={organization.id}
                                                className={classes.option}
                                            >
                                                {organization.name}
                                            </option>
                                        )
                                    })
                                }
                            </NativeSelect>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                id="value"
                                name="value"
                                label={t('custom_value')}
                                type="number"
                                inputProps={{ step: "0.01" }}
                                onChange={handleInputChange}
                                value={inputs.value ? inputs.value : ''}
                                disabled={
                                    appState.isLoading ||
                                    appState.isRefreshing ||
                                    (
                                        grantedOrganizations &&
                                        grantedOrganizations.length === 0
                                    )
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={
                                    appState.isLoading ||
                                    appState.isRefreshing ||
                                    (
                                        grantedOrganizations &&
                                        grantedOrganizations.length === 0
                                    )
                                }
                            >
                                {t('create')}
                            </Button>
                        </FormControl>
                    </FormGroup>
                </form>
            </Grid>
        </Container>
    )
};

export default Create;
import React, {useContext, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
    Button,
    Container,
    Divider,
    FormControl,
    Grid,
    List as MaterialList,
    ListItem,
    ListItemText,
    ListSubheader,
    makeStyles,
    Typography
} from "@material-ui/core";
import {AppContext, AuthContext} from '../../context';
import {useDataAccess} from '../custom_hooks';
import {ConfirmDialog, PrintQuoteDialog} from "../dialog";
import {parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    cartItemOwnerSubHeader: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(-2),
        fontSize: '1.1rem',
        fontWeight: '600',
        fontStyle: 'italic',
    },
    cartItemsListItem: {
        marginLeft: theme.spacing(2),
    },
    amount: {
        alignSelf: 'flex-end',
    },
    actionButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    editFab: {
        zIndex: '1000',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const Show = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        forceRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const classes = useStyles();
    const { fetch } = useDataAccess();
    const [cart, setCart] = useState(null);
    const [organizationsList, setOrganizationsList] = useState([]);
    const [sortedCartItemsList, setSortedCartItemsList] = useState([]);
    const [subtotal, setSubtotal] = useState([]);
    const [total, setTotal] = useState(0);
    const [openDialogValidateCart, setOpenDialogValidateCart] = useState(false);
    const [openDialogClearCart, setOpenDialogClearCart] = useState(false);
    const [openDialogPrint, setOpenDialogPrint] = useState(false);

    useEffect(() => {
        setPageInfos('cart', false);
        setCartFromCache();
        if(
            authState &&
            authState.user &&
            authState.user.cartId
        ) {
            addRefreshResourceAppState(`carts/${authState.user.cartId}`);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCartFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.carts]);

    useEffect(() => {
        if(
            cart &&
            cart.cartItems &&
            cart.cartItems.length > 0
        ) {
            sortCartItemsList(cart.cartItems);
        } else {
            sortCartItemsList([]);
        }
        // eslint-disable-next-line
    }, [cart]);

    useEffect(() => {
        computeTotalAmount();
        // eslint-disable-next-line
    }, [organizationsList, sortedCartItemsList]);

    const setCartFromCache = () => {
        if(
            authState &&
            authState.user &&
            authState.user.cartId &&
            appState.cachedData &&
            appState.cachedData.carts &&
            appState.cachedData.carts[authState.user.cartId]
        ) {
            setCart(appState.cachedData.carts[authState.user.cartId]);
        }
    };

    const updateOrganizationsList = newOrganizationslist => {
        setOrganizationsList(newOrganizationslist);
    };

    const updateSortedCartItemsList = newSortedCartItemslist => {
        setSortedCartItemsList(newSortedCartItemslist);
    };

    const sortCartItemsList = paramCartItemsList => {
        let tmpCartItemsOwners = [];
        let tmpOrganizationsList = [];
        let tmpSortedCartItemsList = [];
        if(null !== paramCartItemsList) {
            Object.keys(paramCartItemsList).map(key => {
                if(!tmpCartItemsOwners.includes(paramCartItemsList[key].productOwner.id)) {
                    tmpCartItemsOwners.push(paramCartItemsList[key].productOwner.id);
                    return tmpOrganizationsList.push({
                        'id': paramCartItemsList[key].productOwner.id,
                        'name': paramCartItemsList[key].productOwner.name,
                        'deliveryStandard': paramCartItemsList[key].productOwner.customDeliveryCost ? paramCartItemsList[key].productOwner.customDeliveryCost[0] : 0,
                        'deliveryExpress': paramCartItemsList[key].productOwner.customDeliveryCost ? paramCartItemsList[key].productOwner.customDeliveryCost[1] : 0,
                    });
                } else {
                    return false;
                }
            });
            tmpCartItemsOwners.map(owner => {
                return Object.keys(paramCartItemsList).map(key => {
                    if(paramCartItemsList[key].productOwner.id === owner) {
                        return tmpSortedCartItemsList.push({'owner': paramCartItemsList[key].productOwner.id, 'cartItem': paramCartItemsList[key]});
                    } else {
                        return false;
                    }
                });
            });
        }
        updateOrganizationsList(tmpOrganizationsList);
        updateSortedCartItemsList(tmpSortedCartItemsList);
    };

    const computeTotalAmount = () => {
        setSubtotal(() => {
            let tmpSubtotal = [];
            if(organizationsList.length > 0 && sortedCartItemsList.length > 0) {
                organizationsList.map(currentOrganization => {
                    return tmpSubtotal[currentOrganization.id] = 0;
                });
                sortedCartItemsList.map(currentCartItem => {
                    return tmpSubtotal[currentCartItem.owner] += (currentCartItem.cartItem.price * currentCartItem.cartItem.quantity);
                });
            }
            return tmpSubtotal;
        });
        if(cart && cart.total) {
            setTotal(cart.total);
        }
    };

    const handleValidateCart = () => {
        props.history.push(`/orders/create/${props.match.params.id}`);
    };

    const handleClearCart = () => {
        fetchInitAppState();
        fetch(
            `carts/${props.match.params.id}/clear_cart`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.json();
            })
            .then(() => {
                fetchSuccessAppState();
                if(
                    authState &&
                    authState.user &&
                    authState.user.cartId
                ) {
                    forceRefreshResourceAppState(`carts/${authState.user.cartId}`);
                }
                showMessageAppState('success', t('cart_cleared'));
                setOpenDialogClearCart(false);
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleOpenDialogValidateCart = () => {
        setOpenDialogValidateCart(true);
    };

    const handleCloseDialogValidateCart = () => {
        setOpenDialogValidateCart(false);
    };

    const handleOpenDialogClearCart = () => {
        setOpenDialogClearCart(true);
    };

    const handleCloseDialogClearCart = () => {
        setOpenDialogClearCart(false);
    };

    const handleOpenDialogPrint = () => {
        setOpenDialogPrint(true);
    };

    const handleCloseDialogPrint = () => {
        setOpenDialogPrint(false);
    };

    if(
        !authState.user.roles.includes('ROLE_SUPERADMIN') &&
        !authState.user.roles.includes('ROLE_RESELLER') &&
        !authState.user.roles.includes('ROLE_CUSTOMER')
    ) {
        return (<Redirect to='/' />)
    } else {
        return (
            <Container>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    className={classes.root}
                >
                    <Grid item xs={12}>
                        <MaterialList component="nav" className={classes.root} aria-label={t('cart_items')}>
                        {
                            organizationsList.length > 0 &&
                            organizationsList.map(organization => {
                                return (
                                    <div key={organization.id}>
                                        <ListSubheader  className={classes.cartItemOwnerSubHeader}>
                                            {organization.name}
                                        </ListSubheader>
                                        {
                                            sortedCartItemsList.length > 0 &&
                                            sortedCartItemsList
                                                .filter(currentItem => currentItem.owner === organization.id)
                                                .map(currentItem => {
                                                    return (
                                                        <div key={currentItem.cartItem['@id']}>
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={currentItem.cartItem.product.name}
                                                                    secondary={
                                                                        <span>
                                                                            <span>{t('unit_price')} : {currentItem.cartItem.price} €</span><br />
                                                                            <span>{t('quantity')} : {currentItem.cartItem.quantity}</span><br />
                                                                            <span>{t('subtotal')} : {currentItem.cartItem.price * currentItem.cartItem.quantity} €</span>
                                                                        </span>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <Divider />
                                                        </div>
                                                    )
                                                })
                                        }
                                        <span>
                                            {t('subtotal')} {organization.name} : {subtotal[organization.id] ? subtotal[organization.id] : 0} {t('currency')}
                                        </span>
                                    </div>
                                )
                            })
                        }
                        {
                            cart &&
                            cart.cartItems &&
                            cart.cartItems.length === 0 &&
                            (
                                appState.isLoading ||
                                appState.isRefreshing
                            ) &&
                            <div>{t('loading')}</div>
                        }
                        {
                            cart &&
                            cart.cartItems &&
                            cart.cartItems.length === 0 &&
                            !appState.isLoading &&
                            !appState.isRefreshing &&
                            <div>{t('empty_cart')}</div>
                        }
                        </MaterialList>
                        {
                            cart &&
                            cart.cartItems &&
                            cart.cartItems.length > 0 &&
                            !appState.isLoading &&
                            !appState.isRefreshing &&
                            <Typography className={classes.amount}>
                                {`${t('total_amount')} : ${total} ${t('currency')}`}
                            </Typography>
                        }
                        {
                            !cart &&
                            appState.isLoading &&
                            appState.isRefreshing &&
                            <div>{t('no_result')}</div>
                        }
                    </Grid>
                    <FormControl className={classes.formControl}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.actionButton}
                            disabled={appState.isLoading || (cart && cart.cartItems && cart.cartItems.length <= 0)}
                            onClick={handleOpenDialogPrint}
                        >
                            {t('print_quote')}
                        </Button>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.actionButton}
                            disabled={appState.isLoading || (cart && cart.cartItems && cart.cartItems.length <= 0)}
                            onClick={handleOpenDialogValidateCart}
                        >
                            {t('validate_cart')}
                        </Button>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            className={classes.actionButton}
                            disabled={appState.isLoading || (cart && cart.cartItems && cart.cartItems.length <= 0)}
                            onClick={handleOpenDialogClearCart}
                        >
                            {t('clear_cart')}
                        </Button>
                    </FormControl>
                </Grid>
                <PrintQuoteDialog
                    open={openDialogPrint}
                    onClose={handleCloseDialogPrint}
                    organizations={organizationsList}
                    cartItems={sortedCartItemsList}
                    subtotal={subtotal}
                    total={total}
                    title='print_quote'
                    {...props}
                />
                <ConfirmDialog
                    open={openDialogValidateCart}
                    onClose={handleCloseDialogValidateCart}
                    onConfirm={handleValidateCart}
                    title='confirm_validate_cart'
                    content='confirm_cart_validate_content'
                    {...props}
                />
                <ConfirmDialog
                    open={openDialogClearCart}
                    onClose={handleCloseDialogClearCart}
                    onConfirm={handleClearCart}
                    title='confirm_clear_cart'
                    content='confirm_cart_clear_content'
                    {...props}
                />
            </Container>
        )
    }
};

export default Show;
import React from "react";
import {useTranslation} from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(2),
        color: theme.palette.text.customPrimary,
    },
    input: {
        display: 'none',
        color: theme.palette.text.customPrimary,
    },
    text: {
        color: theme.palette.text.customPrimary,
    },
}));

const ConfirmDialog = props => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleConfirm = () => {
        props.onConfirm();
    };

    const handleCancel = () => {
        props.onClose();
    };

    const handleClose = () => {
        props.onClose();
    };

    return (
        <Dialog onClose={() => handleClose} aria-labelledby="dialog-title" open={props.open}>
            <DialogTitle id="dialog-title" className={classes.text}>{t(props.title)}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" className={classes.text}>
                    {t(props.content)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary" className={classes.button}>
                    {t('cancel')}
                </Button>
                <Button onClick={handleConfirm} color="primary" className={classes.button}>
                    {t('confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Badge, Container, Grid, IconButton, InputBase, makeStyles, Paper} from "@material-ui/core";
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from '@material-ui/lab';
import {CloudUpload, LibraryAdd, Search, SwapHoriz} from '@material-ui/icons';
import {AppContext, AuthContext} from '../../context';
import {ImportFileDialog} from "../dialog";
import {List as ProductList} from '../product';
import {getIdFromUri} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        maxWidth: 500,
        margin: 'auto',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    input: {
        width: '80%',
        color: theme.palette.text.customPrimary,
    },
    productList: {
        height: '100vh'
    },
    speedDial: {
        zIndex: '1000',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    speedDialAction: {
        zIndex: 1000,
        color: theme.palette.text.customPrimary,
    },
    badge: {
        margin: theme.spacing(1),
    },
}));

const Show = props => {
    const { authState } = useContext(AuthContext);
    const {
        appState,
        addRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const { t } = useTranslation();
    const classes = useStyles();
    const [catalog, setCatalog] = useState(null);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [open, setOpen] = useState(false);
    const [openDialogImportFile, setOpenDialogImportFile] = useState(false);

    useEffect(() => {
        setPageInfos('catalog', false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCatalogFromCache();
        if(props.match.params.id) {
            addRefreshResourceAppState(`catalogs/${props.match.params.id}`);
        }
        // eslint-disable-next-line
    }, [props.match.params.id]);

    useEffect(() => {
        setCatalogFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.catalogs]);

    useEffect(() => {
        if(props.match.params.id > 0 && catalog) {
            setProductsFromCache();
            addRefreshResourceAppState(`products?mainCatalog=${props.match.params.id}`);
        }
        // eslint-disable-next-line
    }, [catalog]);

    useEffect(() => {
        setProductsFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.products]);

    useEffect(() => {
        if(
            products &&
            products.length > 0
        ) {
            setFilteredProducts(products);
        }
        // eslint-disable-next-line
    }, [products]);

    const setCatalogFromCache = () => {
        if(
            appState.cachedData &&
            appState.cachedData.catalogs &&
            appState.cachedData.catalogs[props.match.params.id]
        ) {
            setCatalog(appState.cachedData.catalogs[props.match.params.id]);
        } else {
            setCatalog(null);
        }
    };

    const setProductsFromCache = () => {
        if(
            appState.cachedData &&
            appState.cachedData.products
        ) {
            const tmpProducts = Object.keys(appState.cachedData.products)
                .filter(productId => {
                    if(appState.cachedData.products[productId] && appState.cachedData.products[productId].mainCatalog) {
                        return getIdFromUri(appState.cachedData.products[productId].mainCatalog['@id']) === parseInt(props.match.params.id);
                    }
                    return false;
                })
                .map(productId => appState.cachedData.products[productId])
            if(
                (
                    authState.user.roles.includes('ROLE_SUPERADMIN') &&
                    appState.cachedData.catalogs &&
                    appState.cachedData.catalogs[props.match.params.id] &&
                    appState.cachedData.catalogs[props.match.params.id].products &&
                    products.length === appState.cachedData.catalogs[props.match.params.id].products.length
                ) || (
                    appState.cachedData.productsPerCatalog &&
                    appState.cachedData.productsPerCatalog[props.match.params.id] &&
                    tmpProducts.length === appState.cachedData.productsPerCatalog[props.match.params.id]
                )
            ) {
                setProducts(tmpProducts);
            } else {
                setProducts([]);
            }
        } else {
            setProducts([]);
        }
    };

    const handleManageLinkCatalogOrganization = () => {
        props.history.push(`/catalogs/manage_links/${props.match.params.id}`);
    };

    const handleAddProduct = () => {
        props.history.push(`/catalogs/create_product/${props.match.params.id}`);
    };

    const handleImportProducts = message => {
        showMessageAppState('success', t(message));
        handleCloseDialogImportFile();
        addRefreshResourceAppState(`catalogs/${props.match.params.id}`);
    };

    const handleClick = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };
  
    const handleOpen = () => {
        setOpen(true);
    };

    const handleOpenDialogImportFile = () => {
        setOpenDialogImportFile(true);
    };

    const handleCloseDialogImportFile = () => {
        setOpenDialogImportFile(false);
    };

    const filterProductsList = event => {
        event.persist();
        let tmpFilteredProductsList = products.filter(product => product.name.toLowerCase().includes(event.target.value.toLowerCase()));
        setFilteredProducts(tmpFilteredProductsList);
    };

    return (
        <div className={classes.root}>
            <Container fixed>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                >
                    <Grid item xs={12} className="text-center">
                        <h1>{catalog && catalog.name}</h1>
                    </Grid>
                </Grid>
            </Container>
            <Paper className={classes.paper}>
                <IconButton disabled aria-label="search">
                    <Search />
                </IconButton>
                <InputBase
                    className={classes.input}
                    placeholder={t('filter')}
                    inputProps={{ 'aria-label': 'filter products list' }}
                    onChange={filterProductsList}
                />
            </Paper>
            {
                catalog &&
                <ProductList products={filteredProducts || []} {...props} className={classes.productList} />
            }
            {
                !appState.isError &&
                (
                    appState.isLoading ||
                    appState.isRefreshing
                ) &&
                <div>{t('loading')}</div>
            }
            {
                !catalog &&
                !appState.isError &&
                !appState.isLoading &&
                !appState.isRefreshing &&
                <div>{t('no_result')}</div>
            }
            {
                (
                    catalog &&
                    catalog.owner &&
                    authState.user.organizationId &&
                    (
                        authState.user.roles.includes('ROLE_SUPERADMIN') ||
                        `/api/suppliers/${authState.user.organizationId}` === catalog.owner['@id'] ||
                        `/api/resellers/${authState.user.organizationId}` === catalog.owner['@id']
                    )
                ) && (
                    <SpeedDial
                        ariaLabel="speeddial"
                        className={classes.speedDial}
                        icon={<SpeedDialIcon />}
                        onBlur={handleClose}
                        onClick={handleClick}
                        onClose={handleClose}
                        onFocus={handleOpen}
                        onMouseEnter={handleOpen}
                        onMouseLeave={handleClose}
                        open={open}
                    >
                        <SpeedDialAction
                            key='add_product'
                            title={t('add_product')}
                            className={classes.speedDialAction}
                            icon={<LibraryAdd />}
                            tooltipTitle={t('add_product')}
                            onClick={handleAddProduct}
                        />
                        <SpeedDialAction
                            key='import_products'
                            title={t('import_products')}
                            className={classes.speedDialAction}
                            icon={<CloudUpload />}
                            tooltipTitle={t('import_products')}
                            onClick={handleOpenDialogImportFile}
                        />
                        <SpeedDialAction
                            key='catalog_organizations_link'
                            title={t('catalog_organizations_link')}
                            className={classes.speedDialAction}
                            icon={
                                <Badge className={classes.badge} badgeContent={catalog.grantees.length} color="primary">
                                    <SwapHoriz />
                                </Badge>
                            }
                            tooltipTitle={t('catalog_organizations_link')}
                            onClick={handleManageLinkCatalogOrganization}
                        />
                    </SpeedDial>
                )
            }
            <ImportFileDialog
                open={openDialogImportFile}
                onClose={handleCloseDialogImportFile}
                onConfirm={handleImportProducts}
                title='import_products'
                idCatalog={props.match.params.id}
                content='choose_file'
                {...props}
            />
        </div>
    )
};

export default Show;
import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {AppContext} from '../../context';
import QrReader from "react-qr-reader";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        height: '80vh',
        width: '100%',
        maxWidth: '480px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cancelButton: {
        margin:  theme.spacing(2),
    },
}));

const Scan = props => {
    const { setPageInfos } = useContext(AppContext);
    const classes = useStyles();

    useEffect(() => {
        setPageInfos('scan_product', false);
        // eslint-disable-next-line
    }, []);

    const handleScan = data => {
        if(data) {
            const productUri = data.slice(process.env.REACT_APP_APP_ENTRYPOINT.length - 1);
            props.history.push(productUri);
        }
    };

    const handleError = error => {
        console.error(error)
    };

    return (
        <div className={classes.root}>
            <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: '100%' }}
            />
        </div>
    )
};

export default Scan;
import React, {useContext, useEffect, useState} from 'react';
import {Link, Redirect} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {
    Button,
    CircularProgress,
    Container,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField,
} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {AuthContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import Logo from "../common/Logo";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
    },
    form: {
        width: '90%',
        maxWidth: '480px',
        padding: theme.spacing(2),
        margin: 'auto',
    },
    formControl: {
        background: theme.palette.background.default,
        margin: theme.spacing(1),
    },
    formControlSubmit: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    progress: {
        color: theme.palette.primary.main,
    },
    input: {
        color: 'rgba(255, 255, 255, 0.92) !important',
    },
    underline: {
        '&:before': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.92) !important',
        },
        '&:after': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.92) !important',
        },
        '&:hover:before': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.92) !important',
        },
    },
    icon: {
        color: theme.palette.primary.main,
    },
    linkButton: {
        textDecoration: 'none !important',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
}));

const Login = () => {
    const {
        authState,
        initAuthReducer,
        fetchAuthInit,
        fetchLogin,
        fetchAuthError
    } = useContext(AuthContext);
    const { fetch } = useDataAccess();
    const { t } = useTranslation();
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const handleLogin = () => {
        fetchAuthInit();
        fetch(
            'login',
            {
                method: 'POST', 
                body: {
                    email: inputs.email,
                    password: inputs.password
                }
            }
        )
            .then(response => {
                return response.json();
            })
            .then(json => {
                fetchLogin(json);
            })
            .catch(error => {
                fetchAuthError(error);
            })
    };
    const {inputs, handleInputChange, handleSubmit} = useForm(handleLogin);

    useEffect(() => {
        if(!authState.isLoggedIn) {
            initAuthReducer();
        }
        // eslint-disable-next-line
    }, []);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    if(authState.isLoggedIn) {
        return (
            <Redirect to='/' />
        )
    } else {
        return (
            <div className={classes.root}>
                <Container className={classes.form}>
                    <Grid container>
                        <Grid item xs={12} className="text-center">
                            <Logo />
                        </Grid>
                        <Grid item xs={12} className="text-center">
                            <h6>{t('login')}</h6>
                        </Grid>

                        {authState.isLoading && (
                            <Grid item xs={12} className="text-center">
                                <CircularProgress className={classes.progress} />
                            </Grid>
                        )}
                        {authState.isLoggedIn && (
                            <Grid item xs={12} className="text-center">
                                <div className="alert alert-success" role="status">
                                    {t('success')} !
                                </div>
                            </Grid>
                        )}
                        {authState.isError && (
                            <Grid item xs={12} className="text-center">
                                <div className="alert alert-danger" role="alert">
                                    <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
                                    {t(authState.error)}
                                </div>
                            </Grid>
                        )}

                        <Grid item xs={12} className="text-center">
                            <form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <TextField
                                        margin="normal"
                                        error={authState.isError}
                                        id="email"
                                        name="email"
                                        label={t('email_address')}
                                        type="email"
                                        onChange={handleInputChange}
                                        value={inputs.email ? inputs.email : ''}
                                        className={classes.formControl}
                                        InputProps={{
                                            classes: {
                                                root: classes.input,
                                                underline: classes.underline
                                            }
                                        }}
                                        InputLabelProps={{
                                            className: classes.input
                                        }}
                                        required
                                    />
                                    <TextField
                                        margin="normal"
                                        error={authState.isError}
                                        id="password"
                                        name="password"
                                        label={t('password')}
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={handleInputChange}
                                        value={inputs.password ? inputs.password : ''}
                                        className={classes.formControl}
                                        InputProps={{
                                            classes: {
                                                root: classes.input,
                                                underline: classes.underline
                                            },
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className={classes.icon}
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        InputLabelProps={{
                                            className: classes.input
                                        }}
                                        required
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        className={classes.formControlSubmit}
                                        disabled={authState && authState.isLoading}
                                    >
                                        {t('login')}
                                    </Button>
                                </FormGroup>
                            </form>
                            {
                                authState &&
                                !authState.isLoading &&
                                <Link
                                    to="/register/"
                                    className={classes.linkButton}
                                >
                                    {t('create_account')}
                                </Link>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }
};

export default Login;
import React from 'react';
import {Route} from 'react-router-dom';
import {Wrapper} from '../hooks/common';
import {Create, List, Update} from '../hooks/address/';

export default [
  <Route path="/addresses/create" component={props => <Wrapper component={Create} {...props} />} exact key="create-address" />,
  <Route path="/addresses/edit/:id" component={props => <Wrapper component={Update} {...props} />} exact key="update-address" />,
  <Route path="/addresses" component={props => <Wrapper component={List} {...props} />} exact strict key="list-addresses" />,
  <Route path="/addresses/:page" component={props => <Wrapper component={List} {...props} />} exact strict key="page" />
];

import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Button,
    Container,
    FormControl,
    FormGroup,
    Grid,
    InputLabel,
    makeStyles,
    Select,
    TextField
} from "@material-ui/core";
import {AppContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    option: {
        color: theme.palette.text.customPrimary,
    },
}));

const Update = props => {
    const { t } = useTranslation();
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        setPageInfos,
        showMessageAppState
    } = useContext(AppContext);
    const classes = useStyles();
    const [order, setOrder] = useState({});
    const [addresses, setAddresses] = useState([]);
    const { fetch } = useDataAccess();
    const handleUpdate = () => {
        fetchInitAppState();
        fetch(
            `orders/${props.match.params.id}`,
            {
                method: 'PUT',
                body: {
                    reference: inputs.reference,
                    billingAddress: inputs.billingAddress,
                    shippingAddress: inputs.shippingAddress,
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                addRefreshResourceAppState(`orders/${props.match.params.id}`);
                showMessageAppState('success', t('order_updated'));
                props.history.goBack();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };
    const {inputs, handleInputChange, handleSubmit, setInputs} = useForm(handleUpdate);

    useEffect(() => {
        setPageInfos('edit_address', false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setOrderFromCache();
        addRefreshResourceAppState(`orders/${props.match.params.id}`);
        addRefreshResourceAppState('addresses');
        // eslint-disable-next-line
    }, [props.match.params.id]);

    useEffect(() => {
        setOrderFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.orders]);

    useEffect(() => {
        setAddressesFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.addresses]);

    useEffect(() => {
        order &&
        order.reference &&
        setInputs(() => ({
            reference: order.reference,
            billingAddress: order.billingAddress['@id'],
            shippingAddress: order.shippingAddress['@id'],
        }));
        // eslint-disable-next-line
    }, [order]);

    const setOrderFromCache = () => {
        if(
            appState.cachedData &&
            appState.cachedData.orders &&
            appState.cachedData.orders[props.match.params.id]
        ) {
            setOrder(appState.cachedData.orders[props.match.params.id]);
        }
    };

    const setAddressesFromCache = () => {
        if(
            appState.cachedData &&
            appState.cachedData.addresses
        ) {
            setAddresses(
                Object.keys(appState.cachedData.addresses).map(addressId => appState.cachedData.addresses[addressId])
            );
        }
    };

    return (
        <Container>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
                className={classes.root}
            >
                {
                    appState.isError &&
                    appState.error &&
                    (
                        <Grid item xs={12} className="text-center">
                            <div className="alert alert-danger" role="alert">
                                <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
                                {appState.error}
                            </div>
                        </Grid>
                    )
                }
                <form onSubmit={handleSubmit} className="container">
                    <FormGroup>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="reference"
                                name="reference"
                                label={t('reference')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.reference ? inputs.reference : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="billingAddress">
                                {t('billing_address')}
                            </InputLabel>
                            <Select
                                native
                                required
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                value={inputs.billingAddress || ''}
                                onChange={handleInputChange}
                                inputProps={{
                                    name: 'billingAddress',
                                    id: 'billingAddress',
                                }}
                            >
                                <option value='' />
                                {
                                    addresses &&
                                    addresses.length > 0 &&
                                    addresses.map(address => {
                                        return (
                                            <option key={address['@id']} value={address['@id']} className={classes.option}>{address.line1}</option>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="shippingAddress">
                                {t('shipping_address')}
                            </InputLabel>
                            <Select
                                native
                                required
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                value={inputs.shippingAddress || ''}
                                onChange={handleInputChange}
                                inputProps={{
                                    name: 'shippingAddress',
                                    id: 'shippingAddress',
                                }}
                            >
                                <option value='' />
                                {
                                    addresses &&
                                    addresses.length > 0 &&
                                    addresses.map(address => {
                                        return (
                                            <option key={address['@id']} value={address['@id']} className={classes.option}>{address.line1}</option>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            >
                                {t('update')}
                            </Button>
                        </FormControl>
                    </FormGroup>
                </form>
            </Grid>
        </Container>
    )
};

export default Update;
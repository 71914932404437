import React from "react";
import {useTranslation} from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    List as MaterialList,
    ListItem,
    ListItemText,
    makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(2),
    },
    input: {
        display: 'none',
    },
    text: {
        color: theme.palette.text.customPrimary,
    },
}));

const OrganizationTypeDialog = props => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleClose = () => {
        props.onClose();
    };

    const handleCancel = () => {
        props.onClose();
    };

    const handleListItemClick = organizationType => {
        props.onClick(organizationType);
    };

    return (
        <Dialog onClose={() => handleClose} aria-labelledby="dialog-title" open={props.open}>
            <DialogTitle id="dialog-title" className={classes.text}>{t('user_type')}</DialogTitle>
            <MaterialList>
                <ListItem button onClick={() => handleListItemClick('customers')} className={classes.text}>
                    <ListItemText primary={t('customer')}/>
                </ListItem>
                <ListItem button onClick={() => handleListItemClick('resellers')} className={classes.text}>
                    <ListItemText primary={t('reseller')}/>
                </ListItem>
                <ListItem button onClick={() => handleListItemClick('suppliers')} className={classes.text}>
                    <ListItemText primary={t('supplier')}/>
                </ListItem>
            </MaterialList>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary" className={classes.button}>
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrganizationTypeDialog;
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Divider,
    Fab,
    IconButton,
    List as MaterialList,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles
} from "@material-ui/core";
import {Add, Delete, Edit, Favorite, FavoriteBorder} from '@material-ui/icons';
import {AppContext, AuthContext} from '../../context';
import {useDataAccess} from '../custom_hooks';
import {ConfirmDialog} from "../dialog";
import {getIdFromUri, parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    list: {
        marginBottom: theme.spacing(5),
    },
    addFab: {
        zIndex: '1000',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    icon: {
        color: theme.palette.primary.main,
    },
}));

const List = props => {
    const { t } = useTranslation();
    const {
        authState
    } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        updateCachedDataAppState,
        addRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const classes = useStyles();
    const { fetch } = useDataAccess();
    const [openDialog, setOpenDialog] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [mainAddressId, setMainAddressId] = useState(0);
    const [addressId, setAddressId] = useState(0);

    useEffect(() => {
        setPageInfos('addresses', true);
        setAddressesFromCache();
        addRefreshResourceAppState('addresses');
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setAddressesFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.organization, appState.cachedData.addresses]);

    const setAddressesFromCache = () => {
        if(
            appState.cachedData &&
            appState.cachedData.organization &&
            appState.cachedData.organization[authState.user.organizationId] &&
            appState.cachedData.organization[authState.user.organizationId].mainAddress
        ) {
            if(authState.user.roles.includes('ROLE_SUPERADMIN')) {
                setMainAddressId(getIdFromUri(appState.cachedData.organization[authState.user.organizationId].mainAddress['@id']));
            } else {
                setMainAddressId(getIdFromUri(appState.cachedData.organization[authState.user.organizationId].mainAddress));
            }
        }
        if(appState.cachedData && appState.cachedData.addresses) {
            setAddresses(
                Object.keys(appState.cachedData.addresses)
                    .map(addressId => appState.cachedData.addresses[addressId])
            );
        }
    };

    const handleCreate = () => {
        props.history.push('/addresses/create/');
    };

    const handleEdit = paramAddressId => {
        props.history.push(`/addresses/edit/${paramAddressId}`);
    };

    const handleDelete = () => {
        fetchInitAppState();
        fetch(
            `addresses/${addressId}`,
            {
                method: 'DELETE'
            }
        )
            .then(() => {
                fetchSuccessAppState();
                setAddresses(addresses.filter(address => address.id !== parseInt(addressId)));
                updateCachedDataAppState('addresses', addressId, null);
                showMessageAppState('success', t('address_deleted'));
                handleCloseDialog();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleSetMainAddress = paramMainAddressId => {
        if(mainAddressId !== paramMainAddressId) {
            fetchInitAppState();
            fetch(
                `organizations/${authState.user.organizationId}`,
                {
                    method: 'PUT',
                    body: {
                        mainAddress: `/api/addresses/${paramMainAddressId}`,
                    }
                }
            )
                .then(() => {
                    fetchSuccessAppState();
                    setMainAddressId(paramMainAddressId);
                    addRefreshResourceAppState(`${authState.user.organizationType}s/${authState.user.organizationId}`);
                    showMessageAppState('success', t('main_address_updated'));
                })
                .catch(error => {
                    fetchErrorAppState(parseError(error));
                });
        }
    };

    const handleConfirm = () => {
        handleDelete();
    };

    const handleOpenDialog = paramAddressId => {
        if(
            authState.user.roles.includes('ROLE_SUPERADMIN') ||
            mainAddressId !== paramAddressId
        ) {
            setAddressId(paramAddressId);
            setOpenDialog(true);
        } else {
            showMessageAppState('error', t('cant_delete_main_address'));
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div className={classes.root}>
            <MaterialList component="nav" className={classes.list} aria-label={t('addresses')}>
            {
                addresses &&
                addresses.length > 0 &&
                addresses.map(address => {
                    return (
                        <div key={address['@id']}>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <h4>
                                            {authState.user.roles.includes('ROLE_SUPERADMIN') ? address.organization.name : address.comment}
                                        </h4>
                                    }
                                    secondary={
                                        <span>
                                            <span>{address.line1}</span><br />
                                            {
                                                address.line2 !== null &&
                                                address.line2 !== '' &&
                                                <span>
                                                    <span>
                                                        {address.line2}
                                                    </span>
                                                    <br />
                                                </span>
                                            }
                                            {
                                                address.line3 !== null &&
                                                address.line3 !== '' &&
                                                <span>
                                                    <span>
                                                        {address.line3}
                                                    </span>
                                                    <br />
                                                </span>
                                            }
                                            {
                                                address.countryProvince !== null &&
                                                address.countryProvince !== '' &&
                                                <span>
                                                    <span>
                                                        {address.countryProvince}
                                                    </span>
                                                    <br />
                                                </span>
                                            }
                                            <span>{address.postalCode}</span>&nbsp;
                                            <span>{address.city}</span><br />
                                            <span>{address.country}</span><br />
                                        </span>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    {
                                        !authState.user.roles.includes('ROLE_SUPERADMIN') &&
                                        <IconButton
                                            edge="end"
                                            aria-label="set_default"
                                            onClick={() => handleSetMainAddress(address.id)}
                                            className={classes.icon}
                                            disabled={
                                                appState.isLoading
                                            }
                                        >
                                            {
                                                mainAddressId === address.id ? <Favorite /> : <FavoriteBorder />
                                            }
                                        </IconButton>
                                    }
                                    <IconButton
                                        edge="end"
                                        aria-label="edit"
                                        onClick={() => handleEdit(address.id)}
                                        className={classes.icon}
                                        disabled={
                                            appState.isLoading
                                        }
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        disabled={
                                            appState.isLoading ||
                                            mainAddressId === address.id
                                        }
                                        onClick={() => handleOpenDialog(address.id)}
                                        className={classes.icon}
                                    >
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </div>
                    )
                })
        }
            {
                addresses &&
                addresses.length === 0 &&
                !appState.isError &&
                (
                    appState.isLoading ||
                    appState.isRefreshing
                ) &&
                <div>{t('loading')}</div>
            }
            {
                addresses &&
                addresses.length === 0 &&
                !appState.isError &&
                !appState.isLoading &&
                !appState.isRefreshing &&
                <div>{t('no_result')}</div>
            }
            </MaterialList>
            <Fab
                color="primary"
                aria-label="create"
                className={classes.addFab}
                onClick={handleCreate}
            >
                <Add />
            </Fab>
            <ConfirmDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirm}
                title='confirm_delete'
                content='confirm_address_delete_content'
                {...props}
            />
        </div>
    )
};

export default List;
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Divider,
    IconButton,
    List as MaterialList,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Typography
} from "@material-ui/core";
import {Check, Clear, Delete, QueryBuilder,} from "@material-ui/icons";
import {AppContext, AuthContext} from '../../context';
import {useDataAccess} from '../custom_hooks';
import {ConfirmDialog} from "../dialog";
import {parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    list: {
        height: '40vh',
    },
    addFab: {
        zIndex: '1000',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    icon: {
        color: theme.palette.primary.main,
        '&:disabled': {
            color: theme.palette.text.secondary,
        }
    },
}));

const Request = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        updateCachedDataAppState,
        addRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const classes = useStyles();
    const { fetch } = useDataAccess();
    const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [requestedOrganizations, setRequestedOrganizations] = useState([]);
    const [requesterOrganizations, setRequesterOrganizations] = useState([]);
    const [requestId, setRequestId] = useState(0);

    useEffect(() => {
        setPageInfos('my_requests', true);
        setOrganizationRequestsFromCache();
        addRefreshResourceAppState('organization_requests');
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setOrganizationRequestsFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.organization_requests]);

    const setOrganizationRequestsFromCache = () => {
        if(
            authState.user.organizationId &&
            appState.cachedData &&
            appState.cachedData.organization_requests
        ) {
            let tmpRequestedOrganizations = [], tmpRequesterOrganizations = [];
            Object.keys(appState.cachedData.organization_requests)
                .filter(requestId => !appState.cachedData.organization_requests[requestId].status)
                .map(requestId => {
                    if(appState.cachedData.organization_requests[requestId].requesterOrganization.id === authState.user.organizationId) {
                        return tmpRequestedOrganizations.push(appState.cachedData.organization_requests[requestId]);
                    } else if(appState.cachedData.organization_requests[requestId].requestedOrganization.id === authState.user.organizationId) {
                        return tmpRequesterOrganizations.push(appState.cachedData.organization_requests[requestId]);
                    }
                    return false;
                });
            setRequestedOrganizations(tmpRequestedOrganizations);
            setRequesterOrganizations(tmpRequesterOrganizations);
        }
    };

    const handleAcceptRequest = () => {
        fetchInitAppState();
        fetch(
            `organization_requests/${requestId}`,
            {
                method: 'PUT',
                body: {
                    status: true
                }
            }
        )
            .then(()  => {
                fetchSuccessAppState();
                setRequesterOrganizations();
                updateCachedDataAppState('organization_requests', requestId, {...appState.cachedData.organization_requests[requestId], status: true});
                showMessageAppState('success', t('request_accepted'));
                setOpenAcceptDialog(false);
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleDeleteRequest = () => {
        fetchInitAppState();
        fetch(
            `organization_requests/${requestId}`,
            {
                method: 'DELETE',
            }
        )
            .then(()  => {
                fetchSuccessAppState();
                setRequestedOrganizations(requestedOrganizations.filter(requestedOrganization => requestedOrganization.id !== requestId));
                setRequesterOrganizations(requesterOrganizations.filter(requesterOrganization => requesterOrganization.id !== requestId));
                updateCachedDataAppState('organization_requests', requestId, null);
                showMessageAppState('success', t('request_deleted'));
                setOpenDeleteDialog(false);
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleOpenAcceptDialog = paramRequestId => {
        setRequestId(paramRequestId);
        setOpenAcceptDialog(true);
    };

    const handleOpenDeleteDialog = paramRequestId => {
        setRequestId(paramRequestId);
        setOpenDeleteDialog(true);
    };

    const handleCloseAcceptDialog = () => {
        setOpenAcceptDialog(false);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    return (
        <div className={classes.root}>
            <MaterialList component="nav" className={classes.list} aria-label="sent_requests">
            <Typography>
                {t('sent_requests')}
            </Typography>
            {
                requestedOrganizations &&
                requestedOrganizations.length > 0 &&
                requestedOrganizations.map(request => {
                    return (
                        <div key={request.id}>
                            <ListItem>
                                <ListItemText
                                    primary={request.requestedOrganization.name}
                                    secondary={
                                        request.requestedOrganization.mainAddress &&
                                        <span>
                                            <span>{request.requestedOrganization.mainAddress.line1}</span><br />
                                            {
                                                request.requestedOrganization.mainAddress.line2 !== null &&
                                                request.requestedOrganization.mainAddress.line2 !== '' &&
                                                <span>
                                                    <span>
                                                        {request.requestedOrganization.mainAddress.line2}
                                                    </span>
                                                    <br />
                                                </span>
                                            }
                                            {
                                                request.requestedOrganization.mainAddress.line3 !== null &&
                                                request.requestedOrganization.mainAddress.line3 !== '' &&
                                                <span>
                                                    <span>
                                                        {request.requestedOrganization.mainAddress.line3}
                                                    </span>
                                                    <br />
                                                </span>
                                            }
                                            {
                                                request.requestedOrganization.mainAddress.countryProvince !== null &&
                                                request.requestedOrganization.mainAddress.countryProvince !== '' &&
                                                <span>
                                                    <span>
                                                        {request.requestedOrganization.mainAddress.countryProvince}
                                                    </span>
                                                    <br />
                                                </span>
                                            }
                                            <span>{request.requestedOrganization.mainAddress.postalCode}</span>&nbsp;
                                            <span>{request.requestedOrganization.mainAddress.city}</span><br />
                                            <span>{request.requestedOrganization.mainAddress.country}</span><br />
                                        </span>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="request_in_progress"
                                        disabled
                                        className={classes.icon}
                                    >
                                        <QueryBuilder />
                                    </IconButton>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete_request"
                                        onClick={() => handleOpenDeleteDialog(request.id)}
                                        className={classes.icon}
                                    >
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </div>
                    )
                })
            }
            {
                !appState.isLoading &&
                !appState.isRefreshing &&
                requestedOrganizations &&
                requestedOrganizations.length === 0 &&
                <div>{t('no_result')}</div>
            }
            </MaterialList>
            <MaterialList component="nav" className={classes.list} aria-label="received_requests">
            <Typography>
                {t('received_requests')}
            </Typography>
            {
                requesterOrganizations &&
                requesterOrganizations.length > 0 &&
                requesterOrganizations.map(request => {
                    return (
                        <div key={request.id}>
                            <ListItem>
                                <ListItemText
                                    primary={request.requesterOrganization.name}
                                    secondary={
                                        request.requesterOrganization.mainAddress &&
                                        <span>
                                            <span>{request.requesterOrganization.mainAddress.line1}</span><br />
                                            {
                                                request.requesterOrganization.mainAddress.line2 !== null &&
                                                request.requesterOrganization.mainAddress.line2 !== '' &&
                                                <span>
                                                    <span>
                                                        {request.requesterOrganization.mainAddress.line2}
                                                    </span>
                                                    <br />
                                                </span>
                                            }
                                            {
                                                request.requesterOrganization.mainAddress.line3 !== null &&
                                                request.requesterOrganization.mainAddress.line3 !== '' &&
                                                <span>
                                                    <span>
                                                        {request.requesterOrganization.mainAddress.line3}
                                                    </span>
                                                    <br />
                                                </span>
                                            }
                                            {
                                                request.requesterOrganization.mainAddress.countryProvince !== null &&
                                                request.requesterOrganization.mainAddress.countryProvince !== '' &&
                                                <span>
                                                    <span>
                                                        {request.requesterOrganization.mainAddress.countryProvince}
                                                    </span>
                                                    <br />
                                                </span>
                                            }
                                            <span>{request.requesterOrganization.mainAddress.postalCode}</span>&nbsp;
                                            <span>{request.requesterOrganization.mainAddress.city}</span><br />
                                            <span>{request.requesterOrganization.mainAddress.country}</span><br />
                                        </span>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="accept_request"
                                        onClick={() => handleOpenAcceptDialog(request.id)}
                                        className={classes.icon}
                                    >
                                        <Check />
                                    </IconButton>
                                    <IconButton
                                        edge="end"
                                        aria-label="deny_request"
                                        onClick={() => handleOpenDeleteDialog(request.id)}
                                        className={classes.icon}
                                    >
                                        <Clear />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </div>
                    )
                })
            }
            {
                !appState.isLoading &&
                !appState.isRefreshing &&
                requesterOrganizations &&
                requesterOrganizations.length === 0 &&
                <div>{t('no_result')}</div>
            }
            </MaterialList>
            <ConfirmDialog
                open={openAcceptDialog}
                onClose={handleCloseAcceptDialog}
                onConfirm={handleAcceptRequest}
                title='confirm_organization_request'
                content='confirm_organization_request_accept_content'
                {...props}
            />
            <ConfirmDialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                onConfirm={handleDeleteRequest}
                title='confirm_organization_request'
                content='confirm_organization_request_delete_content'
                {...props}
            />
        </div>
    )
};

export default Request;
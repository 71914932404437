import React from 'react';
import {Route} from 'react-router-dom';
import {Wrapper} from '../hooks/common';
import {Create, List, Request, Update} from '../hooks/organization/';

export default [
  <Route path="/organizations/create/:type" component={props => <Wrapper component={Create} {...props} />} exact key="create-organization" />,
  <Route path="/organizations/edit/:type/:id" component={props => <Wrapper component={Update} {...props} />} exact key="edit-organization" />,
  <Route path="/organizations/:type" component={props => <Wrapper component={List} {...props} />} exact strict key="list-organizations-by-type" />,
  <Route path="/organizations_requests" component={props => <Wrapper component={Request} {...props} />} exact strict key="list-organizations-requests" />,
  <Route path="/organizations" component={props => <Wrapper component={List} {...props} />} exact strict key="list-organizations" />
];

import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Container,
    Grid,
    FormGroup,
    TextField,
    Button,
    makeStyles,
    InputAdornment,
    IconButton,
    FormControl
} from '@material-ui/core';
import {AppContext, AuthContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {Visibility, VisibilityOff} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    formControl: {
        background: theme.palette.background.default,
        margin: theme.spacing(1),
        minWidth: 120,
    },
    input: {
        color: 'rgba(255, 255, 255, 0.92) !important',
    },
    underline: {
        '&:before': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.92) !important',
        },
        '&:after': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.92) !important',
        },
        '&:hover:before': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.92) !important',
        },
    },
    icon: {
        color: theme.palette.primary.main,
    },
    title: {
        color: theme.palette.primary.main
    },
}));

const ResetPassword = props => {
    const {authState, fetchRefresh} = useContext(AuthContext);
    const {
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const {t} = useTranslation();
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const {fetch} = useDataAccess();
    const handleReset = () => {
        fetchInitAppState();
        fetch(
            `/api/users/${authState.user.id}/reset-password`,
            {
                method: 'PUT',
                body: {
                    oldPassword: inputs.currentPassword,
                    newPassword: inputs.newPassword,
                    newRetypedPassword: inputs.newRetypedPassword
                }
            }
        )
            .then(response => {
                return response.json();
            })
            .then(json => {
                fetchRefresh('token', json.token);
                showMessageAppState('success', t('password_updated'));
                fetchSuccessAppState();
                props.history.goBack();
            })
            .catch(error => {
                fetchErrorAppState(error);
            })
    };
    const {inputs, handleInputChange, handleSubmit} = useForm(handleReset);

    useEffect(() => {
        setPageInfos(t('reset_password'), false);
        // eslint-disable-next-line
    }, []);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    return (
        <div className={classes.root}>
            <Container>
                <Grid
                    container
                >
                    {authState.isError && (
                        <Grid item xs={12} className="text-center">
                            <div className="alert alert-danger" role="alert">
                                <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
                                {t(authState.error)}
                            </div>
                        </Grid>
                    )}

                    <Grid item xs={12} className="text-center">
                        <form onSubmit={handleSubmit}>
                            <FormGroup>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        margin="normal"
                                        error={authState.isError}
                                        id="currentPassword"
                                        name="currentPassword"
                                        label={t('current_password')}
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={handleInputChange}
                                        value={inputs.currentPassword ? inputs.currentPassword : ''}
                                        InputProps={{
                                            classes: {
                                                root: classes.input,
                                                underline: classes.underline
                                            },
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className={classes.icon}
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        InputLabelProps={{
                                            className: classes.input
                                        }}
                                        required
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        margin="normal"
                                        error={authState.isError}
                                        id="newPassword"
                                        name="newPassword"
                                        label={t('new_password')}
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={handleInputChange}
                                        helperText={t('password_constraint')}
                                        value={inputs.newPassword ? inputs.newPassword : ''}
                                        InputProps={{
                                            classes: {
                                                root: classes.input,
                                                underline: classes.underline
                                            },
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className={classes.icon}
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        InputLabelProps={{
                                            className: classes.input
                                        }}
                                        required
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        margin="normal"
                                        error={authState.isError}
                                        id="newRetypedPassword"
                                        name="newRetypedPassword"
                                        label={t('retype_password')}
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={handleInputChange}
                                        value={inputs.newRetypedPassword ? inputs.newRetypedPassword : ''}
                                        InputProps={{
                                            classes: {
                                                root: classes.input,
                                                underline: classes.underline
                                            },
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className={classes.icon}
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        InputLabelProps={{
                                            className: classes.input
                                        }}
                                        required
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disabled={
                                            authState.isLoading ||
                                            authState.isRefreshing ||
                                            (
                                                inputs.currentPassword === '' &&
                                                inputs.newPassword === '' &&
                                                inputs.newRetypedPassword === ''
                                            )
                                        }
                                    >
                                        {t('update')}
                                    </Button>
                                </FormControl>
                            </FormGroup>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
};

export default ResetPassword;
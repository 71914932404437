import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    List as MaterialList,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Paper,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {AppContext, AuthContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {getIdFromUri, parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        width: '100%',
        maxHeight: 300,
        overflow: 'auto',
        margin: 'auto',
        paddingTop: theme.spacing(1),
        textAlign: 'center',
        color:  theme.palette.text.customPrimary,
    },
    checkBox: {
        color:  theme.palette.text.customPrimary,
    },
}));

const Create = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        forceRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const classes = useStyles();
    const handleCreate = () => {
        fetchInitAppState();
        fetch(
            'products',
            {
                method: 'POST',
                body: {
                    mainCatalog: props.match.params.catalog > 0 ? '/api/catalogs/' + props.match.params.catalog : inputs.mainCatalog,
                    name: inputs.name,
                    reference: inputs.reference,
                    commercialReference: inputs.commercialReference,
                    price: parseFloat(inputs.price),
                    margin: parseFloat(inputs.margin),
                    averageDepartureDelay: parseInt(inputs.averageDepartureDelay),
                    isInStock: inputs.isInStock,
                    isSparePart:  inputs.isSparePart,
                    suitableProducts: inputs.isSparePart ? checked.map(productId => (`/api/products/${productId}`)) : []
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                forceRefreshResourceAppState(`products?mainCatalog=${props.match.params.catalog > 0 ? props.match.params.catalog : getIdFromUri(inputs.mainCatalog)}`);
                showMessageAppState('success', t('product_created'));
                props.history.goBack();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };
    const {inputs, handleInputChange, handleSubmit} = useForm(handleCreate);
    const {fetch} = useDataAccess();
    const [catalogs, setCatalogs] = useState([]);
    const [products, setProducts] = useState([]);
    const [checked, setChecked] = useState([]);

    useEffect(() => {
        setPageInfos('new_product', false);
        setCatalogsFromCache();
        if(props.match.params.catalog) {
            setProductsFromCache(props.match.params.catalog);
        }
        addRefreshResourceAppState('catalogs');
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCatalogsFromCache();
        if(props.match.params.catalog) {
            setProductsFromCache(props.match.params.catalog);
        } else if(inputs.mainCatalog) {
            setProductsFromCache(getIdFromUri(inputs.mainCatalog));
        }
        // eslint-disable-next-line
    }, [appState.cachedData.catalogs, appState.cachedData.products]);

    useEffect(() => {
        if(inputs.isSparePart) {
            if(props.match.params.catalog) {
                setProductsFromCache(props.match.params.catalog);
            } else if(inputs.mainCatalog) {
                setProductsFromCache(getIdFromUri(inputs.mainCatalog));
            }
        }
        // eslint-disable-next-line
    }, [inputs.isSparePart]);

    useEffect(() => {
        if(inputs.mainCatalog) {
            setProductsFromCache(getIdFromUri(inputs.mainCatalog));
        }
        // eslint-disable-next-line
    }, [inputs.mainCatalog]);

    const setCatalogsFromCache = () => {
        if (
            authState &&
            authState.user &&
            appState.cachedData &&
            appState.cachedData.catalogs
        ) {
            setCatalogs(
                Object.keys(appState.cachedData.catalogs)
                    .filter(catalogId => getIdFromUri(appState.cachedData.catalogs[catalogId].owner['@id']) === authState.user.organizationId)
                    .map(catalogId => appState.cachedData.catalogs[catalogId])
            );
        } else {
            setCatalogs([]);
        }
    }

    const setProductsFromCache = catalogId => {
        if (
            appState.cachedData &&
            appState.cachedData.products
        ) {
            setProducts(
                Object.keys(appState.cachedData.products)
                    .filter(productId => !appState.cachedData.products[productId].isSparePart && getIdFromUri(appState.cachedData.products[productId].mainCatalog['@id']) === parseInt(catalogId))
                    .map(productId => appState.cachedData.products[productId])
            );
        } else {
            setProducts([]);
        }
    };

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    return (
        <Container>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
            >
                {
                    appState.isError &&
                    appState.error &&
                    (
                        <Grid item xs={12} className="text-center">
                            <div className="alert alert-danger" role="alert">
                                <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
                                {appState.error}
                            </div>
                        </Grid>
                    )
                }
                <form onSubmit={handleSubmit} className="container">
                    <FormGroup>
                        {
                            props.match.params.catalog <= 0 && (
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="mainCatalog">
                                        {t('catalog')}
                                    </InputLabel>
                                    <Select
                                        native
                                        required
                                        disabled={
                                            appState.isLoading &&
                                            appState.isRefreshing
                                        }
                                        value={inputs.mainCatalog}
                                        onChange={handleInputChange}
                                        inputProps={{
                                            name: 'mainCatalog',
                                            id: 'mainCatalog',
                                        }}
                                    >
                                        <option value='' />
                                        {
                                            catalogs &&
                                            catalogs.length > 0 &&
                                            catalogs.map(catalog => {
                                                return (
                                                    <option value={catalog['@id']}>{catalog.name}</option>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            )
                        }
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="name"
                                name="name"
                                label={t('product_name')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.name ? inputs.name : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="reference"
                                name="reference"
                                label={t('reference')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.reference ? inputs.reference : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="commercialReference"
                                name="commercialReference"
                                label={t('commercial_reference')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.commercialReference ? inputs.commercialReference : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="price"
                                name="price"
                                label={`${t('price')} (${t('currency')})`}
                                type="number"
                                inputProps={{ step: "0.01" }}
                                onChange={handleInputChange}
                                value={inputs.price ? inputs.price : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="margin"
                                name="margin"
                                label={t('margin')}
                                type="number"
                                inputProps={{ step: "0.01" }}
                                onChange={handleInputChange}
                                value={inputs.margin ? inputs.margin : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="averageDepartureDelay"
                                name="averageDepartureDelay"
                                label={`${t('average_departure_delay')} (${t('day')})`}
                                type="number"
                                inputProps={{ step: "1" }}
                                onChange={handleInputChange}
                                value={inputs.averageDepartureDelay ? inputs.averageDepartureDelay : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={Boolean(inputs.isInStock)}
                                    onChange={handleInputChange}
                                    id="isInStock"
                                    name="isInStock"
                                    value={!inputs.isInStock}
                                    inputProps={{
                                        'aria-label': 'in stock',
                                    }}
                                    disabled={
                                        appState.isLoading &&
                                        appState.isRefreshing
                                    }
                                />
                                }
                                label={t('in_stock')}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={Boolean(inputs.isSparePart)}
                                    onChange={handleInputChange}
                                    id="isSparePart"
                                    name="isSparePart"
                                    value={!inputs.isSparePart}
                                    inputProps={{
                                        'aria-label': 'spare part',
                                    }}
                                    disabled={
                                        appState.isLoading &&
                                        appState.isRefreshing
                                    }
                                />
                                }
                                label={t('spare_part')}
                            />
                        </FormControl>
                        {
                            inputs.isSparePart &&
                            <FormControl className={classes.formControl}>
                                <Paper className={classes.paper}>
                                    <Typography component="p" className={classes.text}>
                                        {t('choose_suitable_products')}
                                    </Typography>
                                    <MaterialList dense component="div" role="list">
                                        {
                                            products &&
                                            products.map(product => {
                                                return (
                                                <ListItem key={product.id} role="listitem" button onClick={handleToggle(product.id)}>
                                                    <ListItemIcon>
                                                    <Checkbox
                                                        checked={checked.indexOf(product.id) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{'aria-labelledby': product.id}}
                                                        className={classes.checkBox}
                                                        disabled={
                                                            appState.isLoading &&
                                                            appState.isRefreshing
                                                        }
                                                    />
                                                    </ListItemIcon>
                                                    <ListItemText id={product.id} primary={product.name} />
                                                </ListItem>
                                                );
                                            })
                                        }
                                    </MaterialList>
                                </Paper>
                            </FormControl>
                        }
                        <FormControl className={classes.formControl}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            >
                                {t('create')}
                            </Button>
                        </FormControl>
                    </FormGroup>
                </form>
            </Grid>
        </Container>
    )
};

export default Create;
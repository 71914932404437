import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Avatar,
    Badge,
    Button,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    FormGroup,
    Grid,
    IconButton,
    InputLabel,
    List as MaterialList,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    TextField,
} from "@material-ui/core";
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from '@material-ui/lab';
import {
    AddShoppingCart,
    Delete,
    Edit,
    EventAvailable,
    EventBusy,
    Favorite,
    FavoriteBorder,
    InsertPhoto,
    LocalOffer,
    SwapHoriz,
    Visibility
} from '@material-ui/icons';
import {AppContext, AuthContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {AddImageDialog, ConfirmDialog, PrintQRCodeDialog, ShowMediaDialog} from "../dialog";
import {getIdFromUri, parseError} from "../../utils/hydraParser";
import QRCode from "qrcode.react";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '85vw',
    },
    showProduct: {
        margin: 'auto',
    },
    qrCodeContainer: {
        margin: 'auto',
        marginBottom: theme.spacing(2),
        padding: 11,
        maxWidth: 150,
        height: 150,
        backgroundColor: theme.palette.background.customPrimary,
    },
    qrCodeItem: {
        width: '100%',
    },
    title: {
        overflowWrap: 'break-word'
    },
    formControl: {
        margin: 'auto',
    },
    addToCartControl: {
        margin: 'auto',
        marginBottom: theme.spacing(2),
        maxWidth: 200,
    },
    textField: {
        margin: 'auto',
        marginTop: theme.spacing(2),
        width: '100%',
    },
    option: {
        color: theme.palette.text.customPrimary,
    },
    addCartIcon: {
        zIndex: '1000',
        margin: theme.spacing(2),
    },
    stockInfos: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    inStock: {
        margin: 'auto',
        padding:  theme.spacing(1),
        width: 'fit-content',
        color: theme.palette.text.customSecondary,
        backgroundColor: theme.palette.background.customPrimary,
    },
    notInStock: {
        margin: 'auto',
        padding:  theme.spacing(1),
        width: 'fit-content',
        backgroundColor: theme.palette.background.customSecondary,
    },
    gridImagesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    mainImg: {
        display: 'block',
        maxWidth: '80vw',
        maxHeight: '60vh',
        width: 'auto',
        height: 'auto',
    },
    imageUrl: {
        width: '60%',
    },
    speedDial: {
        zIndex: '1000',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    speedDialAction: {
        zIndex: 1000,
        color: theme.palette.text.customPrimary,
    },
    badge: {
        margin: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    icon: {
        color: theme.palette.primary.main,
    },
}));

const Show = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        updateCachedDataAppState,
        addRefreshResourceAppState,
        forceRefreshResourceAppState,
        setPageInfos,
        showMessageAppState
    } = useContext(AppContext);
    const classes = useStyles();
    const { fetch } = useDataAccess();
    const [open, setOpen] = useState(false);
    const [openDialogAddImage, setOpenDialogAddImage] = useState(false);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [openDialogDeleteMedia, setOpenDialogDeleteMedia] = useState(false);
    const [openDialogPrint, setOpenDialogPrint] = useState(false);
    const [openDialogShowMedia, setOpenDialogShowMedia] = useState(false);
    const [product, setProduct] = useState(null);
    const [mediaId, setMediaId] = useState(null);
    const [imagesSrc, setImagesSrc] = useState([]);
    const [loadingImagesSrc, setLoadingImagesSrc] = useState(false);
    const [medias, setMedias] = useState([]);

    useEffect(() => {
        setPageInfos('product', false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setProductFromCache();
        if(props.match.params.id) {
            addRefreshResourceAppState(`products/${props.match.params.id}`);
        }
        // eslint-disable-next-line
    }, [props.match.params.id]);

    useEffect(() => {
        setProductFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.products]);

    useEffect(() => {
        const abortController = new AbortController();

        if(product) {
            setImagesSrcFromCache(abortController.signal);
        }

        return () => {
            abortController.abort();
        };
        // eslint-disable-next-line
    }, [product, appState.cachedData.images]);

    const setProductFromCache = () => {
        if(
            appState.cachedData &&
            appState.cachedData.products &&
            appState.cachedData.products[props.match.params.id]
        ) {
            setProduct(appState.cachedData.products[props.match.params.id]);
        } else {
            setProduct(null);
        }
    };

    const setImagesSrcFromCache = signal => {
        if(
            product &&
            product.images &&
            product.images.length > 0
        ) {
            const tmpImagesSrc = [];
            let needRefresh = false;
            product.images.map(image => {
                const currentImageId = getIdFromUri(image);
                if(
                    appState.cachedData &&
                    appState.cachedData.images &&
                    appState.cachedData.images[currentImageId]
                ) {
                    return tmpImagesSrc[currentImageId] = appState.cachedData.images[currentImageId];
                }
                return needRefresh = true;
            });
            if(!needRefresh && imagesSrc !== tmpImagesSrc) {
                setImagesSrc(tmpImagesSrc);
            } else if(needRefresh && !loadingImagesSrc) {
                setImagesSrcFromApi(signal);
            }
        } else {
            setImagesSrc([]);
        }
    };

    const setImagesSrcFromApi = signal => {
        if(
            product &&
            product.images &&
            product.images.length > 0
        ) {
            setLoadingImagesSrc(true);
            setImagesSrc([]);
            Promise.all(product.images.map(image => fetch(`media_objects/${getIdFromUri(image)}`, { method: 'GET', signal: signal  })))
                .then(response => Promise.all(response.map(resp => resp.json())))
                .then(values => {
                    values.map(json => {
                        return updateCachedDataAppState('images', getIdFromUri(json['@id']), `${process.env.REACT_APP_API_ENTRYPOINT}${json.contentUrl}`);
                    });
                    setLoadingImagesSrc(false);
                })
                .catch(errors => {
                    setLoadingImagesSrc(false);
                    if (Array.isArray(errors) && errors.length > 0) {
                        errors.map(error => fetchErrorAppState(error));
                    } else {
                        fetchErrorAppState(errors);
                    }
                });
        }
    };

    const handleAddCart = () => {
        fetchInitAppState();
        fetch(
            'cart_items',
            {
                method: 'POST',
                body: {
                    product: `/api/products/${props.match.params.id}`,
                    quantity: inputs.quantity
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                forceRefreshResourceAppState(`carts/${authState.user.cartId}`);
                showMessageAppState('success', t('product_added_to_cart'));
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };
    const {inputs, handleInputChange, handleSubmit} = useForm(handleAddCart);

    const handleEdit = () => {
        props.history.push(`/catalogs/edit_product/${props.match.params.id}`);
    };

    const handleShow = productId => {
        props.history.push(`/catalogs/show_product/${productId}`);
    };

    const handleAddImage = imageId => {
        fetchInitAppState();
        fetch(
            `products/${props.match.params.id}`,
            {
                method: 'PUT',
                body: {
                    images: [...product.images, imageId]
                }
            }
        )
            .then(response => {
                return response.json();
            })
            .then(json => {
                fetchSuccessAppState();
                updateCachedDataAppState('products', props.match.params.id, json);
                showMessageAppState('success', t('image_added'));
                handleCloseDialogAddImage();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleToggleStockAvailability = () => {
        fetchInitAppState();
        fetch(
            `products/${props.match.params.id}`,
            {
                method: 'PUT',
                body: {
                    isInStock: !product.isInStock
                }
            }
        )
            .then(response => {
                return response.json();
            })
            .then(json => {
                fetchSuccessAppState();
                updateCachedDataAppState('products', props.match.params.id, json);
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleDelete = () => {
        fetchInitAppState();
        fetch(
            `products/${props.match.params.id}`,
            {
                method: 'DELETE'
            }
        )
            .then(() => {
                fetchSuccessAppState();
                updateCachedDataAppState('products', props.match.params.id, null);
                showMessageAppState('success', t('product_deleted'));
                props.history.goBack();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleDeleteMedia = () => {
        fetchInitAppState();
        fetch(
            `media_objects/${mediaId}`,
            {
                method: 'DELETE'
            }
        )
            .then(() => {
                fetchSuccessAppState();
                setProduct({...product, images: [...product.images.filter(image => getIdFromUri(image) !== mediaId)]});
                updateCachedDataAppState('products', props.match.params.id, {...product, images: [...product.images.filter(image => getIdFromUri(image) !== mediaId)]});
                setImagesSrc(imagesSrc.filter((image, index) => index !== mediaId));
                showMessageAppState('success', t('media_deleted'));
                handleCloseDialogDeleteMedia();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleSetMainImage = paramMainImageId => {
        if(getIdFromUri(product.mainImage) !== paramMainImageId) {
            fetchInitAppState();
            fetch(
                `products/${props.match.params.id}`,
                {
                    method: 'PUT',
                    body: {
                        mainImage: `/api/media_objects/${paramMainImageId}`
                    }
                }
            )
                .then(() => {
                    fetchSuccessAppState();
                    setProduct({...product, mainImage: `/api/media_objects/${paramMainImageId}`});
                    updateCachedDataAppState('products', props.match.params.id, {...product, mainImage: `/api/media_objects/${paramMainImageId}`});
                    showMessageAppState('success', t('main_image_updated'));
                })
                .catch(error => {
                    fetchErrorAppState(parseError(error));
                });
        }
    };

    const handleManageLinkProductOrganization = () => {
        props.history.push(`/products/manage_links/${props.match.params.id}`);
    };

    const handleCustomMargins = () => {
        props.history.push(`/products/show_custom_margins/${props.match.params.id}`);
    };

    const handleClick = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleOpenDialogAddImage = () => {
        setOpenDialogAddImage(true);
    };

    const handleCloseDialogAddImage = () => {
        setOpenDialogAddImage(false);
    };

    const handleOpenDialogDelete = () => {
        setOpenDialogDelete(true);
    };

    const handleCloseDialogDelete = () => {
        setOpenDialogDelete(false);
    };

    const handleOpenDialogDeleteMedia = mediaId => {
        setMediaId(mediaId);
        setOpenDialogDeleteMedia(true);
    };

    const handleCloseDialogDeleteMedia = () => {
        setOpenDialogDeleteMedia(false);
    };

    const handleOpenDialogPrint = () => {
        setOpenDialogPrint(true);
    };

    const handleCloseDialogPrint = () => {
        setOpenDialogPrint(false);
    };

    const handleOpenDialogShowMedia = () => {
        setMedias(imagesSrc);
        setOpenDialogShowMedia(true);
    };

    const handleCloseDialogShowMedia = () => {
        setOpenDialogShowMedia(false);
    };

    return (
        <Container className={classes.root}>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
            >
                <Grid item xs={12}>
                    {
                        product &&
                        <div>
                            <Grid container>
                                <Grid item xs={12} md={4} className="text-center">
                                    <Paper
                                        className={classes.qrCodeContainer}
                                        onClick={handleOpenDialogPrint}
                                    >
                                        <QRCode value={`${process.env.REACT_APP_APP_ENTRYPOINT}catalogs/show_product/${product.id}`} />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4} className='text-center'>
                                    <Typography variant={'h5'} className={classes.title}>
                                        {product.name}
                                    </Typography>
                                </Grid>
                                {
                                    product.mainCatalog &&
                                    authState &&
                                    authState.user &&
                                    !authState.user.roles.includes('ROLE_SUPERADMIN') &&
                                    authState.user.organizationId &&
                                    authState.user.organizationId !== parseInt(getIdFromUri(product.owner)) &&
                                    <Grid item xs={12} md={4} className='text-center'>
                                        <form onSubmit={handleSubmit} className={classes.addToCartControl}>
                                            <FormGroup>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel htmlFor="quantity">
                                                        {t('quantity')}
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        disabled={
                                                            appState.isLoading &&
                                                            appState.isRefreshing
                                                        }
                                                        value={inputs.quantity || 0}
                                                        onChange={handleInputChange}
                                                        inputProps={{
                                                            name: 'quantity',
                                                            id: 'quantity',
                                                        }}
                                                    >
                                                        <MenuItem value={0} className={classes.option}>0</MenuItem>
                                                        <MenuItem value={1} className={classes.option}>1</MenuItem>
                                                        <MenuItem value={2} className={classes.option}>2</MenuItem>
                                                        <MenuItem value={3} className={classes.option}>3</MenuItem>
                                                        <MenuItem value={4} className={classes.option}>4</MenuItem>
                                                        <MenuItem value={5} className={classes.option}>5</MenuItem>
                                                        <MenuItem value={6} className={classes.option}>6</MenuItem>
                                                        <MenuItem value={7} className={classes.option}>7</MenuItem>
                                                        <MenuItem value={8} className={classes.option}>8</MenuItem>
                                                        <MenuItem value={9} className={classes.option}>9</MenuItem>
                                                    </Select>
                                                    <Button
                                                        type='submit'
                                                        aria-label={t('product')}
                                                        className={classes.showProduct}
                                                        disabled={
                                                            appState.isLoading ||
                                                            appState.isRefreshing ||
                                                            !inputs.quantity ||
                                                            inputs.quantity < 1
                                                        }
                                                    >
                                                        <AddShoppingCart />
                                                    </Button>
                                                </FormControl>
                                            </FormGroup>
                                        </form>
                                    </Grid>
                                }
                                <Grid item xs={12} className={classes.stockInfos}>
                                    {
                                        product.isInStock &&
                                        <Paper className={classes.inStock}>
                                            <strong>{t('in_stock')}</strong>
                                        </Paper>
                                    }
                                    {
                                        !product.isInStock &&
                                        <Paper className={classes.notInStock}>
                                            <strong>{t('not_in_stock')}</strong>
                                        </Paper>
                                    }
                                    {
                                        product.averageDepartureDelay &&
                                        product.averageDepartureDelay > 0 &&
                                        <small>
                                            {t('average_departure_delay')} : {product.averageDepartureDelay} {t('days')}
                                        </small>
                                    }
                                </Grid>
                                <Grid item xs={12} className='text-center'>
                                    {
                                        product.mainCatalog &&
                                        authState.user.organizationId &&
                                        (
                                            authState.user.roles.includes('ROLE_SUPERADMIN') ||
                                            authState.user.organizationId === parseInt(getIdFromUri(product.owner))
                                        ) &&
                                        <TextField
                                            margin="normal"
                                            id="reference"
                                            name="reference"
                                            label={t('reference')}
                                            type="text"
                                            className={classes.textField}
                                            variant="outlined"
                                            value={product.reference ? product.reference : ''}
                                            inputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    }
                                    <TextField
                                        margin="normal"
                                        id="commercialReference"
                                        name="commercialReference"
                                        label={t('commercial_reference')}
                                        type="text"
                                        className={classes.textField}
                                        variant="outlined"
                                        value={product.commercialReference ? product.commercialReference : ''}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                    />
                                    {
                                        (
                                            product.price &&
                                            product.mainCatalog &&
                                            authState.user.organizationId &&
                                            (
                                                authState.user.roles.includes('ROLE_SUPERADMIN') ||
                                                authState.user.organizationId === parseInt(getIdFromUri(product.owner))
                                            )
                                        ) && (
                                            <TextField
                                                margin="normal"
                                                id="unit_price"
                                                name="unit_price"
                                                label={t('unit_price')}
                                                type="text"
                                                className={classes.textField}
                                                variant="outlined"
                                                value={product.price ? `${product.price} €` : ''}
                                                inputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        (
                                            product.margin &&
                                            product.mainCatalog &&
                                            authState.user.organizationId &&
                                            (
                                                authState.user.roles.includes('ROLE_SUPERADMIN') ||
                                                authState.user.organizationId === parseInt(getIdFromUri(product.owner))
                                            )
                                        ) && (
                                            <TextField
                                                margin="normal"
                                                id="default_margin"
                                                name="default_margin"
                                                label={t('default_margin')}
                                                type="text"
                                                className={classes.textField}
                                                variant="outlined"
                                                value={product.margin ? product.margin : ''}
                                                inputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        (
                                            product.customPrice
                                        ) && (
                                            <TextField
                                                margin="normal"
                                                id="price"
                                                name="price"
                                                label={t('price')}
                                                type="text"
                                                className={classes.textField}
                                                variant="outlined"
                                                value={product.customPrice ? `${product.customPrice} €` : ''}
                                                inputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        )
                                    }
                                </Grid>
                                <Grid item xs={12} className='text-center'>
                                    <TextField
                                        margin="normal"
                                        id="description"
                                        name="description"
                                        label={t('description')}
                                        multiline
                                        className={classes.textField}
                                        variant="outlined"
                                        value={product.description ? product.description : ''}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {
                                product.spareParts &&
                                product.spareParts.length > 0 &&
                                <div>
                                    <Typography>
                                        {t('spare_parts')}
                                    </Typography>
                                    <MaterialList component="nav" aria-label={t('spare_parts')}>
                                        {
                                            product.spareParts.map(sparePart => {
                                                return (
                                                    <div key={sparePart.id}>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={sparePart.name}
                                                                secondary={sparePart.CommercialReference}
                                                            />
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="see"
                                                                    onClick={() => handleShow(sparePart.id)}
                                                                    className={classes.icon}
                                                                >
                                                                    <Visibility />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <Divider />
                                                    </div>
                                                )
                                            })
                                        }
                                    </MaterialList>
                                </div>
                            }
                            {
                                product.suitableProducts &&
                                product.suitableProducts.length > 0 &&
                                <div>
                                    <Typography>
                                        {t('suitable_products')}
                                    </Typography>
                                    <MaterialList component="nav" aria-label={t('spare_parts')}>
                                        {
                                            product.suitableProducts.map(suitableProduct => {
                                                return (
                                                    <div key={suitableProduct.id}>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={suitableProduct.name}
                                                                secondary={suitableProduct.CommercialReference}
                                                            />
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="see"
                                                                    onClick={() => handleShow(suitableProduct.id)}
                                                                    className={classes.icon}
                                                                >
                                                                    <Visibility />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <Divider />
                                                    </div>
                                                )
                                            })
                                        }
                                    </MaterialList>
                                </div>
                            }
                            <Grid container>
                                <Grid item xs={12} className='text-center'>
                                    {
                                        product.mainImage &&
                                        imagesSrc &&
                                        imagesSrc.length > 0 &&
                                        <div className={classes.gridImagesContainer}>
                                            <img
                                                src={imagesSrc[getIdFromUri(product.mainImage)]}
                                                className={classes.mainImg}
                                                alt={t('main_image')}
                                            />
                                        </div>
                                    }
                                    {
                                        loadingImagesSrc &&
                                        <CircularProgress />
                                    }
                                </Grid>
                            </Grid>
                            {
                                (
                                    product.mainCatalog &&
                                    authState.user.organizationId &&
                                    (
                                        authState.user.roles.includes('ROLE_SUPERADMIN') ||
                                        authState.user.organizationId === parseInt(getIdFromUri(product.owner))
                                    )
                                ) && (
                                    <Grid container>
                                        <Grid item xs={12} className='text-center'>
                                            {
                                                imagesSrc &&
                                                imagesSrc.length > 0 &&
                                                <MaterialList>
                                                    {
                                                        imagesSrc.map((image, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <ListItem>
                                                                        <ListItemAvatar>
                                                                            <Avatar
                                                                                alt={image}
                                                                                src={image}
                                                                            />
                                                                        </ListItemAvatar>
                                                                        <ListItemSecondaryAction>
                                                                                <IconButton
                                                                                    edge="end"
                                                                                    aria-label="set_default"
                                                                                    onClick={() => handleSetMainImage(index)}
                                                                                    className={classes.icon}
                                                                                    disabled={
                                                                                        appState.isLoading
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        (
                                                                                            product.mainImage &&
                                                                                            getIdFromUri(product.mainImage) === index
                                                                                        ) ? <Favorite /> : <FavoriteBorder />
                                                                                    }
                                                                                </IconButton>
                                                                            <IconButton
                                                                                edge="end"
                                                                                aria-label="see"
                                                                                onClick={() => handleOpenDialogDeleteMedia(index)}
                                                                                className={classes.icon}
                                                                            >
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                    <Divider />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </MaterialList>
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </div>
                    }
                    {
                        !product &&
                        !appState.isError &&
                        (
                            appState.isLoading ||
                            appState.isRefreshing
                        ) &&
                        <div>{t('loading')}</div>
                    }
                    {
                        !product &&
                        !appState.isError &&
                        !appState.isLoading &&
                        !appState.isRefreshing &&
                        <div>{t('no_result')}</div>
                    }
                </Grid>
            </Grid>
            {
                (
                    product &&
                    product.mainCatalog &&
                    product.customMargins &&
                    product.grantedOrganizations &&
                    authState.user.organizationId &&
                    (
                        authState.user.roles.includes('ROLE_SUPERADMIN') ||
                        authState.user.organizationId === parseInt(getIdFromUri(product.owner))
                    )
                ) && (
                    <SpeedDial
                        ariaLabel="speeddial"
                        className={classes.speedDial}
                        icon={<SpeedDialIcon />}
                        onBlur={handleClose}
                        onClick={handleClick}
                        onClose={handleClose}
                        onFocus={handleOpen}
                        onMouseEnter={handleOpen}
                        onMouseLeave={handleClose}
                        open={open}
                    >
                        <SpeedDialAction
                            key='product_organizations_link'
                            title={t('product_organizations_link')}
                            className={classes.speedDialAction}
                            icon={
                                <Badge className={classes.badge} badgeContent={product.grantedOrganizations.length} color="primary">
                                    <SwapHoriz />
                                </Badge>
                            }
                            tooltipTitle={t('product_organizations_link')}
                            onClick={handleManageLinkProductOrganization}
                        />
                        <SpeedDialAction
                            key='custom_margins'
                            title={t('custom_margins')}
                            className={classes.speedDialAction}
                            icon={
                                <Badge className={classes.badge} badgeContent={product.customMargins.length} color="primary">
                                    <LocalOffer />
                                </Badge>
                            }
                            tooltipTitle={t('custom_margins')}
                            onClick={handleCustomMargins}
                            disabled={product.grantedOrganizations.length <= 0}
                        />
                        <SpeedDialAction
                            key='add_image'
                            title={t('add_image')}
                            className={classes.speedDialAction}
                            icon={<InsertPhoto />}
                            tooltipTitle={t('add_image')}
                            onClick={handleOpenDialogAddImage}
                        />
                        <SpeedDialAction
                            key='show_image'
                            title={t('show_images')}
                            className={classes.speedDialAction}
                            icon={<InsertPhoto />}
                            tooltipTitle={t('show_images')}
                            onClick={handleOpenDialogShowMedia}
                        />
                        <SpeedDialAction
                            key='toggle_stock_availability'
                            title={t('toggle_stock_availability')}
                            className={classes.speedDialAction}
                            icon={product && product.isInStock ? <EventBusy /> : <EventAvailable />}
                            tooltipTitle={t('toggle_stock_availability')}
                            onClick={handleToggleStockAvailability}
                        />
                        <SpeedDialAction
                            key='edit'
                            title={t('edit_product')}
                            className={classes.speedDialAction}
                            icon={<Edit />}
                            tooltipTitle={t('edit_product')}
                            onClick={handleEdit}
                        />
                        <SpeedDialAction
                            key='delete'
                            title={t('delete_product')}
                            className={classes.speedDialAction}
                            icon={<Delete />}
                            tooltipTitle={t('delete_product')}
                            onClick={handleOpenDialogDelete}
                        />
                    </SpeedDial>
                )
            }
            <AddImageDialog
                open={openDialogAddImage}
                onClose={handleCloseDialogAddImage}
                onConfirm={handleAddImage}
                title='add_image'
                content='choose_image_to_add'
                {...props}
            />
            <ConfirmDialog
                open={openDialogDelete}
                onClose={handleCloseDialogDelete}
                onConfirm={handleDelete}
                title='confirm_delete'
                content='confirm_product_delete_content'
                {...props}
            />
            <ConfirmDialog
                open={openDialogDeleteMedia}
                onClose={handleCloseDialogDeleteMedia}
                onConfirm={handleDeleteMedia}
                title='confirm_delete'
                content='confirm_image_delete_content'
                {...props}
            />
            {
                product &&
                <PrintQRCodeDialog
                    open={openDialogPrint}
                    onClose={handleCloseDialogPrint}
                    product={product}
                    title='print_qrcode'
                    {...props}
                />
            }
            <ShowMediaDialog
                open={openDialogShowMedia}
                onClose={handleCloseDialogShowMedia}
                medias={medias}
                title='show_medias'
                {...props}
            />
        </Container>
    )
};

export default Show;
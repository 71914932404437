import React, {useContext} from 'react';
import {Redirect} from 'react-router-dom';
import {AuthContext} from '../../context';

const Wrapper = ({ component: Component, ...rest }) => {
    const { authState } = useContext(AuthContext);

    if(authState.isLoggedIn) {
        return (
            <Component {...rest} />
        )
    } else {
        return (
            <Redirect to="/login" />
        )
    }
    
};

export default Wrapper;
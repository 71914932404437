import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    List as MaterialList,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Paper,
    TextField,
    Typography
} from "@material-ui/core";
import {AppContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        width: '100%',
        maxHeight: 300,
        overflow: 'auto',
        margin: 'auto',
        paddingTop: theme.spacing(1),
        textAlign: 'center',
        color:  theme.palette.text.customPrimary,
    },
    checkBox: {
        color:  theme.palette.text.customPrimary,
    },
}));

const Update = props => {
    const { t } = useTranslation();
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        forceRefreshResourceAppState,
        setPageInfos,
        showMessageAppState
    } = useContext(AppContext);
    const classes = useStyles();
    const [product, setProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const [checked, setChecked] = useState([]);
    const { fetch } = useDataAccess();
    const handleUpdate = () => {
        fetchInitAppState();
        fetch(
            `products/${props.match.params.id}`,
            {
                method: 'PUT',
                body: {
                    name: inputs.name,
                    reference: inputs.reference,
                    commercialReference: inputs.commercialReference,
                    description: inputs.description,
                    price: parseFloat(inputs.price),
                    margin: parseFloat(inputs.margin),
                    averageDepartureDelay: parseInt(inputs.averageDepartureDelay),
                    isInStock: inputs.isInStock,
                    isSparePart:  inputs.isSparePart,
                    suitableProducts: inputs.isSparePart ? checked.map(productId => (`/api/products/${productId}`)) : []
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                forceRefreshResourceAppState(`products/${props.match.params.id}`);
                showMessageAppState('success', t('product_updated'));
                props.history.goBack();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };
    const {inputs, handleInputChange, handleSubmit, setInputs} = useForm(handleUpdate);

    useEffect(() => {
        setPageInfos('edit_product', false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setProductFromCache();
        setProductsFromCache();
        addRefreshResourceAppState('products');
        // eslint-disable-next-line
    }, [props.match.params.id]);

    useEffect(() => {
        setProductFromCache();
        setProductsFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.products]);

    useEffect(() => {
        setInputs(inputs => ({...inputs, ...product}));
        // eslint-disable-next-line
    }, [product]);

    useEffect(() => {
        if(
            product &&
            product.suitableProducts &&
            product.suitableProducts.length > 0
        ) {
            const newChecked = [...checked];
            product.suitableProducts.map(suitableProduct => {
                return newChecked.push(suitableProduct.id);
            });
            setChecked(newChecked);
        }
        // eslint-disable-next-line
    }, [products]);

    const setProductFromCache = () => {
        if(
            appState.cachedData &&
            appState.cachedData.products &&
            appState.cachedData.products[props.match.params.id]
        ) {
            setProduct(appState.cachedData.products[props.match.params.id]);
        } else {
            setProduct(null);
        }
    };

    const setProductsFromCache = () => {
        if (
            appState.cachedData &&
            appState.cachedData.products
        ) {
            setProducts(
                Object.keys(appState.cachedData.products)
                    .filter(productId => !appState.cachedData.products[productId].isSparePart && appState.cachedData.products[productId].mainCatalog['@id'] === appState.cachedData.products[props.match.params.id].mainCatalog['@id'])
                    .map(productId => appState.cachedData.products[productId])
            );
        } else {
            setProducts([]);
        }
    };

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    return (
        <Container>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
            >
                {
                    appState.isError &&
                    appState.error &&
                    (
                        <Grid item xs={12} className="text-center">
                            <div className="alert alert-danger" role="alert">
                                <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
                                {appState.error}
                            </div>
                        </Grid>
                    )
                }
                <form onSubmit={handleSubmit} className="container">
                    <FormGroup>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="name"
                                name="name"
                                label={t('product_name')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.name ? inputs.name : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="reference"
                                name="reference"
                                label={t('reference')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.reference ? inputs.reference : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="commercialReference"
                                name="commercialReference"
                                label={t('commercial_reference')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.commercialReference ? inputs.commercialReference : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="price"
                                name="price"
                                label={`${t('price')} (${t('currency')})`}
                                type="number"
                                inputProps={{ step: "0.01" }}
                                onChange={handleInputChange}
                                value={inputs.price ? inputs.price : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="margin"
                                name="margin"
                                label={t('margin')}
                                type="number"
                                inputProps={{ step: "0.01" }}
                                onChange={handleInputChange}
                                value={inputs.margin ? inputs.margin : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="description"
                                name="description"
                                label={t('description')}
                                multiline
                                onChange={handleInputChange}
                                value={inputs.description ? inputs.description : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="averageDepartureDelay"
                                name="averageDepartureDelay"
                                label={`${t('average_departure_delay')} (${t('day')})`}
                                type="number"
                                inputProps={{ step: "1" }}
                                onChange={handleInputChange}
                                value={inputs.averageDepartureDelay ? inputs.averageDepartureDelay : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={Boolean(inputs.isInStock)}
                                    onChange={handleInputChange}
                                    id="isInStock"
                                    name="isInStock"
                                    value={!inputs.isInStock}
                                    inputProps={{
                                        'aria-label': 'in stock',
                                    }}
                                    disabled={
                                        appState.isLoading &&
                                        appState.isRefreshing
                                    }
                                />
                                }
                                label={t('in_stock')}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(inputs.isSparePart)}
                                        onChange={handleInputChange}
                                        id="isSparePart"
                                        name="isSparePart"
                                        value={!inputs.isSparePart}
                                        inputProps={{
                                            'aria-label': 'spare part',
                                        }}
                                        disabled={
                                            appState.isLoading &&
                                            appState.isRefreshing
                                        }
                                    />
                                }
                                label={t('spare_part')}
                            />
                        </FormControl>
                        {
                            inputs.isSparePart &&
                            <FormControl className={classes.formControl}>
                                <Paper className={classes.paper}>
                                    <Typography component="p" className={classes.text}>
                                        {t('choose_suitable_products')}
                                    </Typography>
                                    <MaterialList dense component="div" role="list">
                                        {
                                            products &&
                                            products.map(product => {
                                                return (
                                                    product.id !== parseInt(props.match.params.id) ? (
                                                        <ListItem key={product.id} role="listitem" button onClick={handleToggle(product.id)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    checked={checked.indexOf(product.id) !== -1}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{ 'aria-labelledby': product.id }}
                                                                    className={classes.checkBox}
                                                                    disabled={
                                                                        appState.isLoading &&
                                                                        appState.isRefreshing
                                                                    }
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={product.id} primary={product.name} />
                                                        </ListItem>
                                                    ) : ''
                                                );
                                            })
                                        }
                                    </MaterialList>
                                </Paper>
                            </FormControl>
                        }
                        <FormControl className={classes.formControl}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            >
                                {t('update')}
                            </Button>
                        </FormControl>
                    </FormGroup>
                </form>
            </Grid>
        </Container>
    )
};

export default Update;
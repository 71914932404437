import React from 'react';
import {Route} from 'react-router-dom';
import {Wrapper} from '../hooks/common';
import {Create, List, Show, Update} from '../hooks/order/';

export default [
  <Route path="/orders/create/:cartId" component={props => <Wrapper component={Create} {...props} />} exact key="create-order" />,
  <Route path="/orders/edit/:id" component={props => <Wrapper component={Update} {...props} />} exact key="edit-order" />,
  <Route path="/orders/show/:id" component={props => <Wrapper component={Show} {...props} />} exact key="show-order" />,
  <Route path="/my_orders/show/:id" component={props => <Wrapper component={Show} {...props} />} exact key="show-my-order" />,
  <Route path="/received_orders/show/:id" component={props => <Wrapper component={Show} {...props} />} exact key="show-received-order" />,
  <Route path="/orders" component={props => <Wrapper component={List} {...props} />} exact strict key="list-orders" />,
  <Route path="/my_orders" component={props => <Wrapper component={List} type='ordered' {...props} />} exact strict key="list-my-orders" />,
  <Route path="/received_orders" component={props => <Wrapper component={List} type='received' {...props} />} exact strict key="list-received-orders" />,
  <Route path="/orders/:page" component={props => <Wrapper component={List} {...props} />} exact strict key="list-page-orders" />
];

import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    GridList,
    makeStyles
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(2),
        color: theme.palette.text.customPrimary,
    },
    text: {
        color: theme.palette.text.customPrimary,
        textAlign: 'center',
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    img: {
        maxHeight: 200,
    },
}));

const ShowMediaDialog = props => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [cols, setCols] = useState(2);
    const mdBp = useMediaQuery(theme => theme.breakpoints.up('md'));
    const lgBp = useMediaQuery(theme => theme.breakpoints.up('lg'));

    useEffect(() => {
        if(lgBp) return setCols(4);
        if(mdBp && !lgBp) return setCols(2);
        if(!mdBp) return setCols(1);
    }, [mdBp, lgBp]);

    const handleCancel = () => {
        props.onClose();
    };

    const handleClose = () => {
        props.onClose();
    };

    return (
        <Dialog onClose={() => handleClose} aria-labelledby="dialog-title" open={props.open}>
            <DialogTitle id="dialog-title" className={classes.text}>{t(props.title)}</DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-slide-description"
                    className={classes.text}
                >
                    <GridList className={classes.gridList} cols={cols}>
                        {
                            props.medias.map(media => {
                                return (
                                    <img
                                        src={`${process.env.REACT_APP_API_ENTRYPOINT}${media.url}`}
                                        key={media.id}
                                        className={classes.img}
                                        alt={media.id}
                                    />
                                )}
                            )
                        }
                    </GridList>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary" className={classes.button}>
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ShowMediaDialog;
import React from 'react';
import {Route} from 'react-router-dom';
import {Wrapper} from '../hooks/common';
import {Create, Links, List, Show, Update} from '../hooks/product/';
import {List as CustomMarginsList} from '../hooks/custommargin/';

export default [
  <Route path="/products/create/:catalog" component={props => <Wrapper component={Create} {...props} />} exact key="create-product" />,
  <Route path="/products/edit/:id" component={props => <Wrapper component={Update} {...props} />} exact key="update-product" />,
  <Route path="/products/show/:id" component={props => <Wrapper component={Show} {...props} />} exact key="show-product" />,
  <Route path="/products/show_custom_margins/:id" component={props => <Wrapper component={CustomMarginsList} {...props} />} exact key="show-custom-margins-product" />,
  <Route path="/products/manage_links/:id" component={props => <Wrapper component={Links} {...props} />} exact key="manage-links-product" />,
  <Route path="/products" component={props => <Wrapper component={List} {...props} />} exact strict key="list-products" />,
  <Route path="/products/:page" component={props => <Wrapper component={List} {...props} />} exact strict key="list-page-products" />
];

import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Divider,
    Fab,
    IconButton,
    InputBase,
    List as MaterialList,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    makeStyles,
    Paper
} from "@material-ui/core";
import {Add, Delete, Edit, Search,} from '@material-ui/icons';
import {AppContext, AuthContext} from '../../context';
import {useDataAccess} from '../custom_hooks';
import {ConfirmDialog} from "../dialog";
import {getIdFromUri, parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    list: {
        marginBottom: theme.spacing(5),
    },
    catalogOwnerSubHeader: {
        color: theme.palette.primary.main,
        fontSize: '1.1rem',
        fontWeight: '600',
        fontStyle: 'italic',
    },
    catalogListItem: {
        marginLeft: theme.spacing(2),
    },
    paper: {
        maxWidth: 500,
        margin: 'auto',
        marginTop: theme.spacing(1),
    },
    input: {
        width: '80%',
        color: theme.palette.text.customPrimary,
    },
    addFab: {
        zIndex: '1000',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    icon: {
        zIndex: '1000',
        color: theme.palette.primary.main,
    },
}));

const List = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        updateCachedDataAppState,
        addRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const classes = useStyles();
    const {fetch} = useDataAccess();
    const [catalogs, setCatalogs] = useState([]);
    const [organizationsList, setOrganizationsList] = useState([]);
    const [sortedCatalogsList, setSortedCatalogsList] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [catalogId, setCatalogId] = useState(0);

    useEffect(() => {
        switch(props.type) {
            case 'owner':
                setPageInfos('own_catalogs', true);
                break;
            case 'granted':
                setPageInfos('granted_catalogs', true);
                break;
            default:
                setPageInfos('catalogs', true);
        }
        setCatalogsFromCache();
        addRefreshResourceAppState('catalogs');
        addRefreshResourceAppState(`${authState.user.organizationType}s/${authState.user.organizationId}`);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCatalogsFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.catalogs, appState.cachedData.organization]);

    useEffect(() => {
        if(catalogs && catalogs.length > 0) {
            sortCatalogsList(catalogs);
        }
        // eslint-disable-next-line
    }, [catalogs]);

    const setCatalogsFromCache = () => {
        switch(props.type) {
            case 'owner':
                if(
                    appState.cachedData &&
                    appState.cachedData.catalogs &&
                    appState.cachedData.organization &&
                    appState.cachedData.organization[authState.user.organizationId] &&
                    appState.cachedData.organization[authState.user.organizationId].ownCatalogs &&
                    appState.cachedData.organization[authState.user.organizationId].ownCatalogs.length > 0
                ) {
                    const tmpOwnCatalogsList = [];
                    appState.cachedData.organization[authState.user.organizationId].ownCatalogs.map(catalog => {
                        const currentCatalogId = getIdFromUri(catalog['@id']);
                        if(appState.cachedData.catalogs[currentCatalogId]) {
                            return tmpOwnCatalogsList[currentCatalogId] = appState.cachedData.catalogs[currentCatalogId];
                        } else {
                            return false;
                        }
                    });
                    setCatalogs(tmpOwnCatalogsList);
                } else {
                    setCatalogs([]);
                }
                break;
            case 'granted':
                if(
                    appState.cachedData &&
                    appState.cachedData.catalogs &&
                    appState.cachedData.organization &&
                    appState.cachedData.organization[authState.user.organizationId] &&
                    appState.cachedData.organization[authState.user.organizationId].grantedCatalogs &&
                    appState.cachedData.organization[authState.user.organizationId].grantedCatalogs.length > 0
                ) {
                    const tmpGrantedCatalogsList = [];
                    appState.cachedData.organization[authState.user.organizationId].grantedCatalogs.map(catalog => {
                        const currentCatalogId = getIdFromUri(catalog['@id']);
                        if(appState.cachedData.catalogs[currentCatalogId]) {
                            return tmpGrantedCatalogsList[currentCatalogId] = appState.cachedData.catalogs[currentCatalogId];
                        } else {
                            return false;
                        }
                    });
                    setCatalogs(tmpGrantedCatalogsList);
                } else {
                    setCatalogs([]);
                }
                break;
            default:
                if(appState.cachedData && appState.cachedData.catalogs) {
                    setCatalogs(
                        Object.keys(appState.cachedData.catalogs)
                            .map(catalogId => appState.cachedData.catalogs[catalogId]));
                } else {
                    setCatalogs([]);
                }
        }
    };
    
    const handleEdit = (paramCatalogId, event) => {
        event.stopPropagation();
        props.history.push(`/catalogs/edit/${paramCatalogId}`);
    };

    const handleDelete = () => {
        fetchInitAppState();
        fetch(
            `catalogs/${catalogId}`,
            {
                method: 'DELETE'
            }
        )
            .then(() => {
                fetchSuccessAppState();
                setCatalogs(catalogs.filter(catalog => catalog.id !== parseInt(catalogId)));
                updateCachedDataAppState('catalogs', catalogId, null);
                showMessageAppState('success', t('catalog_deleted'));
                handleCloseDialog();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleCreate = () => {
        props.history.push('/catalogs/create/');
    };

    const handleShowCatalog = catalog => {
        props.history.push(`/catalogs/show/${catalog.id}`);
    };

    const handleConfirm = () => {
        handleDelete();
    };

    const handleOpenDialog = (paramCatalogId, event) => {
        event.stopPropagation();
        setCatalogId(paramCatalogId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const updateOrganizationsList = newOrganizationslist => {
        setOrganizationsList(newOrganizationslist);
    };

    const updateSortedCatalogsList = newSortedCatalogslist => {
        setSortedCatalogsList(newSortedCatalogslist);
    };

    const sortCatalogsList = paramCatalogsList => {
        let tmpCatalogsOwners = [];
        let tmpOrganizationsList = [];
        let tmpSortedCatalogsList = [];
        if(null !== paramCatalogsList) {
            Object.keys(paramCatalogsList).map(key => {
                if(!tmpCatalogsOwners.includes(paramCatalogsList[key].owner['@id'])) {
                    tmpCatalogsOwners.push(paramCatalogsList[key].owner['@id']);
                    return tmpOrganizationsList.push({'id': paramCatalogsList[key].owner['@id'], 'name': paramCatalogsList[key].owner.name});
                } else {
                    return false;
                }
            });
            tmpCatalogsOwners.map(owner => {
                return Object.keys(paramCatalogsList).map(key => {
                    if(
                        paramCatalogsList[key] &&
                        paramCatalogsList[key].owner &&
                        paramCatalogsList[key].owner['@id'] === owner
                    ) {
                        return tmpSortedCatalogsList.push({'owner': paramCatalogsList[key].owner['@id'], 'catalog': paramCatalogsList[key]});
                    } else {
                        return false;
                    }
                });
            });
        }
        updateOrganizationsList(tmpOrganizationsList);
        updateSortedCatalogsList(tmpSortedCatalogsList);
    };

    const filterSortedCatalogsList = event => {
        event.persist();
        let tmpFilteredCatalogsList = catalogs.filter(catalog => catalog.name.toLowerCase().includes(event.target.value.toLowerCase()));
        sortCatalogsList(tmpFilteredCatalogsList);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <IconButton disabled aria-label="search">
                    <Search />
                </IconButton>
                <InputBase
                    className={classes.input}
                    placeholder={t('filter')}
                    inputProps={{ 'aria-label': 'filter catalogs list' }}
                    onChange={filterSortedCatalogsList}
                />
            </Paper>
            <MaterialList component="nav" className={classes.list} aria-label={t('catalogs')}>
            {
                organizationsList.map(organization => {
                    return (
                        <div key={organization.id}>
                            <ListSubheader className={classes.catalogOwnerSubHeader}>
                                {organization.name}
                            </ListSubheader>
                            {
                                sortedCatalogsList.length > 0 &&
                                sortedCatalogsList
                                    .filter(currentItem => currentItem.owner === organization.id)
                                    .map(currentItem => {
                                        return (
                                            <div key={currentItem.catalog.id}>
                                                <ListItem
                                                    button
                                                    onClick={() => handleShowCatalog(currentItem.catalog)}
                                                >
                                                    {
                                                        currentItem.catalog.products &&
                                                        authState.user &&
                                                        currentItem.catalog.owner.id === authState.user.organizationId &&
                                                        <div>
                                                            <ListItemText
                                                                primary={currentItem.catalog.name}
                                                                secondary={`${t('products_number')} : ${currentItem.catalog.products.length}`}
                                                                className={classes.catalogListItem}
                                                            />
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="edit"
                                                                    onClick={event => handleEdit(currentItem.catalog.id, event)}
                                                                    className={classes.icon}
                                                                >
                                                                    <Edit />
                                                                </IconButton>
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="delete"
                                                                    onClick={event => handleOpenDialog(currentItem.catalog.id, event)}
                                                                    className={classes.icon}
                                                                >
                                                                    <Delete />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </div>
                                                    }
                                                    {
                                                        authState.user &&
                                                        currentItem.catalog.owner.id !== authState.user.organizationId &&
                                                        <div>
                                                            <ListItemText
                                                                primary={currentItem.catalog.name}
                                                                className={classes.catalogListItem}
                                                            />
                                                            {
                                                                authState.user.roles.includes('ROLE_SUPERADMIN') &&
                                                                <ListItemSecondaryAction>
                                                                    <IconButton
                                                                        edge="end"
                                                                        aria-label="edit"
                                                                        onClick={event => handleEdit(currentItem.catalog.id, event)}
                                                                        className={classes.icon}
                                                                    >
                                                                        <Edit />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        edge="end"
                                                                        aria-label="delete"
                                                                        onClick={event => handleOpenDialog(currentItem.catalog.id, event)}
                                                                        className={classes.icon}
                                                                    >
                                                                        <Delete />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            }
                                                        </div>
                                                    }
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        )
                                    })
                            }
                        </div>
                    )
                })
            }
            {
                catalogs &&
                catalogs.length === 0 &&
                !appState.isError &&
                (
                    appState.isLoading ||
                    appState.isRefreshing
                ) &&
                <div>{t('loading')}</div>
            }
            {
                catalogs &&
                catalogs.length === 0 &&
                !appState.isError &&
                !appState.isLoading &&
                !appState.isRefreshing &&
                <div>{t('no_result')}</div>
            }
            </MaterialList>
            {
                (
                    authState.user.roles.includes('ROLE_SUPERADMIN') ||
                    (
                        props.type === 'owner' && (
                            authState.user.roles.includes('ROLE_ADMIN') ||
                            authState.user.roles.includes('ROLE_SUPPLIER') ||
                            authState.user.roles.includes('ROLE_RESELLER')
                        )
                    )
                ) && (
                    <div>
                        <Fab
                            color="primary"
                            aria-label="create"
                            className={classes.addFab}
                            onClick={handleCreate}
                        >
                            <Add />
                        </Fab>
                        <ConfirmDialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            onConfirm={handleConfirm}
                            title='confirm_delete'
                            content='confirm_catalog_delete_content'
                            {...props}
                        />
                    </div>
                )
            }
        </div>
    )
};

export default List;
import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar,
    Badge,
    CircularProgress,
    CssBaseline,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Snackbar,
    Toolbar,
    Typography
} from '@material-ui/core';
import {
    Archive,
    Business,
    ChevronLeft,
    Close,
    ExitToApp,
    Home as HomeIcon,
    Menu,
    Refresh,
    Room,
    ShoppingCart,
    SupervisorAccount,
    Unarchive,
    VerticalSplit
} from "@material-ui/icons";
import isEqual from 'lodash/isEqual';
import SnackbarContentWrapper from './SnackbarContentWrapper';
import {AppContext, AuthContext} from '../context';
import {getIdFromUri, parseError, renderLinks} from '../utils/hydraParser';
import {useDataAccess} from './custom_hooks';
import Logo from "./common/Logo";

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
    '@keyframes blinker': {
        from: {opacity: 1},
        to: {opacity: 0}
    },
    rootLoggedIn: {
        width: '100%',
        display: 'flex',
        maxWidth: '100vw',
        maxHeight: '100vh',
    },
    rootNotLoggedIn: {
        margin: 'auto',
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    refreshingApp: {
        position: 'fixed',
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            left: `calc(${theme.spacing(1)}px + ${drawerWidth}px)`,
        },
        animationName: '$blinker',
        animationDuration: '1s',
        animationTimingFunction: 'linear',
        animationIterationCount:'infinite',
    },
    circularProgress: {
        marginRight: theme.spacing(1),
        color: theme.palette.primary.main,
    },
    linearProgress: {
        backgroundColor: theme.palette.background.default,
    },
    toolBar: {
        justifyContent: 'space-between',
    },
    toolBarLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none !important',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    closeSnackbar: {
        padding: theme.spacing(0.5),
    },
    actionButton: {
        margin: theme.spacing(0.5),
        padding: theme.spacing(0.5),
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
    },
    drawerHeaderUser: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
    },
    icon: {
        color: theme.palette.primary.main,
    },
    iconDrawer: {
        color: theme.palette.text.customPrimary,
    },
    itemDrawer: {
        margin: theme.spacing(-1),
        color: theme.palette.text.customPrimary,
    },
    logoutDrawer: {
        backgroundColor: theme.palette.background.customSecondary,
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.background.default,
        }
    },
    content: {    
        flexGrow: 1,
        margin: theme.spacing(3),
        maxWidth: '100%',
        maxHeight: '100%',
    },
    footer: {
        width: '100%',
        height: theme.spacing(3),
    },
    divider: {
        backgroundColor: theme.palette.background.default,
    },
    dividerFullWidth: {
        margin: `5px 0 0 ${theme.spacing(1)}px`,
        color: theme.palette.text.customPrimary,
    },
    appLogo: {
        width: '100%',
        textAlign: 'center',
    },
    viewContainer: {
        height: 'calc(100vh - 100px)', // TODO : Try to get the real height 100vh - ToolbarHeight
    },
    margin: {
        margin: theme.spacing(1),
    }
}));

const HomeDrawer = props => {
    const { t } = useTranslation();
    const {
        authState,
        logout
    } = useContext(AuthContext);
    const {
        appState,
        initAppState,
        initCachedDataAppState,
        fetchErrorAppState,
        updateCachedDataAppState,
        setRefreshStatusAppState,
        addRefreshResourceAppState,
        removeRefreshResourceAppState,
        initMessageAppState
    } = useContext(AppContext);
    const classes = useStyles();
    const { fetch } = useDataAccess();
    const [mobileOpen, setMobileOpen] = useState(false);

    useEffect(() => {
        if(
            authState &&
            authState.isLoggedIn &&
            appState &&
            appState.refreshResources &&
            !appState.isRefreshing &&
            !appState.isError &&
            Object.keys(appState.refreshResources).filter(resource => appState.refreshResources[resource].status).length > 0
        ) {
            refreshResources();
        }
        // eslint-disable-next-line
    }, [appState.refreshResources, appState.isRefreshing, appState.isError]);

    useEffect(() => {
        if(authState && authState.isLoggedIn) {
            handleRefreshApp();
        }
        // eslint-disable-next-line
    }, [authState.isLoggedIn]);

    const refreshResources = () => {
        setRefreshStatusAppState(true);
        Promise.all(Object.keys(appState.refreshResources)
            .filter(resource => appState.refreshResources[resource].status)
            .map(resource =>  fetch(resource, { method: 'GET' })))
            .then(response => Promise.all(response.map(resp => resp.json())))
            .then(values => {
                values.map(json => {
                    const currentResource = json['@id'].slice(5).toLowerCase();
                    if(
                        authState &&
                        authState.user &&
                        !authState.user.roles.includes('ROLE_SUPERADMIN') &&
                        currentResource === 'organizations'
                    ) {
                        removeRefreshResourceAppState('get-all-organizations');
                    } else if(json['hydra:view'] && json['hydra:view']['@id']) {
                        const currentViewResource = json['hydra:view']['@id'].slice(5);
                        const tmpSplitUri = currentViewResource.split('&');
                        let pagination = false;
                        tmpSplitUri.map(parameter => {
                            if(parameter.includes('page')) {
                                pagination = true;
                                return removeRefreshResourceAppState(tmpSplitUri[0]);
                            }
                            return false;
                        });
                        if(!pagination) {
                            removeRefreshResourceAppState(currentViewResource);
                        }
                    } else {
                        removeRefreshResourceAppState(currentResource);
                    }
                    if(json['hydra:view'] && json['hydra:view']['@id']) {
                        const tmpSplitUri = json['hydra:view']['@id'].slice(5).split('?');
                        const resource = tmpSplitUri[0];
                        const tmpSplitParameters = tmpSplitUri[1].split('&');
                        if(tmpSplitParameters.length > 1) {
                            tmpSplitParameters.map(parameter => {
                                const tmpSplitParameterValue = parameter.split('=');
                                const parameterName = tmpSplitParameterValue[0];
                                const idResource = tmpSplitParameterValue[1];
                                if(resource === 'products' && parameterName === 'mainCatalog') {
                                    return updateCache(json['hydra:totalItems'], 'productsPerCatalog', idResource);
                                }
                                return false;
                            })
                        } else {
                            const tmpSplitParameterValue = tmpSplitParameters[0].split('=');
                            const parameterName = tmpSplitParameterValue[0];
                            const idResource = tmpSplitParameterValue[1];
                            if(resource === 'products' && parameterName === 'mainCatalog') {
                                updateCache(json['hydra:totalItems'], 'productsPerCatalog', idResource);
                            }
                        }
                    }
                    if(json['@type'] === 'hydra:Collection') {
                        return json['hydra:member'].map(currentItem => {
                            return updateCache(currentItem, currentResource, getIdFromUri(currentItem['@id']));
                        });
                    } else {
                        const organizationResourceTypes = ['supplier', 'reseller', 'customer'];
                        let resourceType = `${json['@type'].toLowerCase()}s`;
                        if(organizationResourceTypes.includes(json['@type'].toLowerCase())) {
                            resourceType = 'organization';
                        } else if(json['@type'] === 'Address') {
                            resourceType = 'addresses';
                        }
                        return updateCache(json, resourceType, getIdFromUri(json['@id']));
                    }
                });
                setRefreshStatusAppState(false);
            })
            .catch(errors => {
                setRefreshStatusAppState(false);
                if(Array.isArray(errors) && errors.length > 0) {
                    errors.map(error => {
                        checkErrorResource(error);
                        return fetchErrorAppState(error.message ? error.message : error);
                    });
                } else {
                    checkErrorResource(errors);
                    return fetchErrorAppState(errors.message ? errors.message : errors);
                }
            });
    };

    // Check if cache is already up to date (to prevent unnecessary request)
    const updateCache = (newItem, resource, index) => {
        if(
            appState.cachedData &&
            appState.cachedData[resource] &&
            appState.cachedData[resource][index] &&
            isEqual(newItem, appState.cachedData[resource][index])
        ) {
            return;
        }
        return updateCachedDataAppState(resource, index, newItem);
    };

    // Check if error contains resource url to remove it from appState.refreshResource (to prevent loop on refreshResources)
    const checkErrorResource = error => {
        if(error.message) {
            const parsedError = parseError(error.message);
            if(
                appState.refreshResources &&
                appState.refreshResources[parsedError] &&
                appState.refreshResources[parsedError].status
            ) {
                removeRefreshResourceAppState(parsedError);
            }
        }
    };

    const handleRefreshApp = () => {
        if(!appState || !appState.cachedData) {
            initCachedDataAppState();
        }
        let refreshResources = [];
        switch(authState.user.roles[0]) {
            case 'ROLE_SUPERADMIN':
                refreshResources = [
                    `${authState.user.organizationType}s/${authState.user.organizationId}`,
                    'catalogs',
                    'orders',
                    'organizations',
                    'addresses',
                    'users',
                ];
                break;
            case 'ROLE_SUPPLIER':
                refreshResources = [
                    `${authState.user.organizationType}s/${authState.user.organizationId}`,
                    'catalogs',
                    'organization_requests',
                    'orders',
                    'addresses',
                    'customers',
                    'resellers',
                ];
                break;
            case 'ROLE_RESELLER':
                refreshResources = [
                    `${authState.user.organizationType}s/${authState.user.organizationId}`,
                    'catalogs',
                    'orders',
                    'organization_requests',
                    `carts/${authState.user.cartId}`,
                    'addresses',
                    'customers',
                    'suppliers',
                ];
                break;
            case 'ROLE_CUSTOMER':
                refreshResources = [
                    `${authState.user.organizationType}s/${authState.user.organizationId}`,
                    'catalogs',
                    'orders',
                    'organization_requests',
                    `carts/${authState.user.cartId}`,
                    'addresses',
                    'resellers',
                    'suppliers',
                ];
                break;
            default:
        }
        refreshResources.map(resource => addRefreshResourceAppState(resource));
    };

    const handleGoBack = () => {
        props.history.goBack();
    };
    
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleSnackbarClose = () => {
        initMessageAppState();
    };

    const handleLogout = () => {
        initAppState();
        logout();
    };

    const drawerContent = (
        <div>
            <div className={classes.drawerHeader}>
            {
                mobileOpen &&
                <IconButton onClick={handleDrawerToggle} className={classes.icon}>
                    <ChevronLeft />
                </IconButton>
            }
            </div>
            <div className={classes.drawerHeaderUser}>
                <Typography>
                    {
                        authState.user &&
                        <strong>
                            {authState.user.firstName} {authState.user.lastName}
                        </strong>
                    }
                </Typography>
                <div className="small">
                    {authState.user && authState.user.email}
                </div>
                <div className="small">
                    {
                        authState.user &&
                        renderLinks('organizations', authState.user.organizationId, authState.user.organizationName)
                    }
                </div>
            </div>
            <Divider className={classes.divider} />
            <List>
                <Link to="/">
                    <ListItem
                        button
                        className={classes.itemDrawer}
                    >
                        <ListItemIcon className={classes.iconDrawer}><HomeIcon /></ListItemIcon>
                        <ListItemText primary={t('home')} />
                    </ListItem>
                </Link>
            </List>
            {
                authState.user &&
                !authState.user.roles.includes('ROLE_SUPERADMIN') && (
                    <div>
                        <Divider className={classes.divider} />
                        <span>
                            <Typography
                                className={classes.dividerFullWidth}
                                color="textSecondary"
                                display="block"
                                variant="caption"
                            >
                                {t('products_management')}
                            </Typography>
                        </span>
                        <List>
                            {
                                (
                                    authState.user &&
                                    (
                                        authState.user.roles.includes('ROLE_SUPPLIER') ||
                                        authState.user.roles.includes('ROLE_RESELLER')
                                    )
                                ) && (
                                    <Link to="/own_catalogs">
                                        <ListItem
                                            button
                                            className={classes.itemDrawer}
                                        >
                                            <ListItemIcon className={classes.iconDrawer}><VerticalSplit /></ListItemIcon>
                                            <ListItemText primary={t('own_catalogs')} />
                                        </ListItem>
                                    </Link>
                                )
                            }
                            {
                                (
                                    authState.user &&
                                    (
                                        authState.user.roles.includes('ROLE_RESELLER') ||
                                        authState.user.roles.includes('ROLE_CUSTOMER')
                                    )
                                ) && (
                                    <Link to="/granted_catalogs">
                                        <ListItem
                                            button
                                            className={classes.itemDrawer}
                                        >
                                            <ListItemIcon className={classes.iconDrawer}><VerticalSplit /></ListItemIcon>
                                            <ListItemText primary={t('granted_catalogs')} />
                                        </ListItem>
                                    </Link>
                                )
                            }
                        </List>
                        <Divider className={classes.divider} />
                        <span>
                            <Typography
                                className={classes.dividerFullWidth}
                                color="textSecondary"
                                display="block"
                                variant="caption"
                            >
                                {t('orders_management')} 
                            </Typography>
                        </span>
                        <List>
                            {
                                (
                                    authState.user &&
                                    (
                                        authState.user.roles.includes('ROLE_RESELLER') ||
                                        authState.user.roles.includes('ROLE_CUSTOMER')
                                    )
                                ) && (
                                    <Link to="/my_orders">
                                        <ListItem
                                            button
                                            className={classes.itemDrawer}
                                        >
                                            <ListItemIcon className={classes.iconDrawer}>
                                                <Unarchive />
                                            </ListItemIcon>
                                            <Badge
                                                badgeContent={
                                                    appState.cachedData.orders &&
                                                    Object.keys(appState.cachedData.orders).length > 0 &&
                                                    Object.keys(appState.cachedData.orders).filter(orderId => (
                                                        appState.cachedData.orders[orderId].purchaser.id === authState.user.organizationId &&
                                                        appState.cachedData.orders[orderId].status === 0
                                                    )).length
                                                }
                                                color="secondary"
                                            >
                                                <ListItemText primary={t('my_orders')}  />
                                            </Badge>
                                        </ListItem>
                                    </Link>
                                )
                            }
                            {
                                (
                                    authState.user &&
                                    (
                                        authState.user.roles.includes('ROLE_SUPPLIER') ||
                                        authState.user.roles.includes('ROLE_RESELLER')
                                    )
                                ) && (
                                    <Link to="/received_orders">
                                        <ListItem
                                            button
                                            className={classes.itemDrawer}
                                        >
                                            <ListItemIcon className={classes.iconDrawer}>
                                                <Archive />
                                            </ListItemIcon>
                                            <Badge
                                                badgeContent={
                                                    appState.cachedData.orders &&
                                                    Object.keys(appState.cachedData.orders).length > 0 &&
                                                    Object.keys(appState.cachedData.orders).filter(orderId => (
                                                        appState.cachedData.orders[orderId].seller.id === authState.user.organizationId &&
                                                        appState.cachedData.orders[orderId].status === 0
                                                    )).length
                                                }
                                                color="secondary"
                                            >
                                                <ListItemText primary={t('received_orders')}  />
                                            </Badge>
                                        </ListItem>
                                    </Link>
                                )
                            }
                        </List>
                        <Divider className={classes.divider} />
                        {
                            authState.user &&
                            (
                                authState.user.roles.includes('ROLE_SUPPLIER') ||
                                authState.user.roles.includes('ROLE_RESELLER') ||
                                authState.user.roles.includes('ROLE_CUSTOMER')
                            ) && (
                                <div>
                                    <span>
                                        <Typography
                                            className={classes.dividerFullWidth}
                                            color="textSecondary"
                                            display="block"
                                            variant="caption"
                                        >
                                            {t('organizations_management')}
                                        </Typography>
                                    </span>
                                    <List>
                                        {
                                            (
                                                authState.user &&
                                                authState.user.roles.includes('ROLE_SUPPLIER')
                                            ) && (
                                                <div>
                                                    <Link to="/organizations/resellers">
                                                        <ListItem
                                                            button
                                                            className={classes.itemDrawer}
                                                        >
                                                            <ListItemIcon className={classes.iconDrawer}><Business /></ListItemIcon>
                                                            <ListItemText primary={t('my_resellers')}  />
                                                        </ListItem>
                                                    </Link>
                                                    <Link to="/organizations/customers">
                                                        <ListItem
                                                            button
                                                            className={classes.itemDrawer}
                                                        >
                                                            <ListItemIcon className={classes.iconDrawer}><Business /></ListItemIcon>
                                                            <ListItemText primary={t('my_customers')}  />
                                                        </ListItem>
                                                    </Link>
                                                </div>
                                            )
                                        }
                                        {
                                            (
                                                authState.user &&
                                                authState.user.roles.includes('ROLE_RESELLER')
                                            ) && (
                                                <div>
                                                    <Link to="/organizations/suppliers">
                                                        <ListItem
                                                            button
                                                            className={classes.itemDrawer}
                                                        >
                                                            <ListItemIcon className={classes.iconDrawer}><Business /></ListItemIcon>
                                                            <ListItemText primary={t('my_suppliers')}  />
                                                        </ListItem>
                                                    </Link>
                                                    <Link to="/organizations/customers">
                                                        <ListItem
                                                            button
                                                            className={classes.itemDrawer}
                                                        >
                                                            <ListItemIcon className={classes.iconDrawer}><Business /></ListItemIcon>
                                                            <ListItemText primary={t('my_customers')}  />
                                                        </ListItem>
                                                    </Link>
                                                </div>
                                            )
                                        }
                                        {
                                            (
                                                authState.user &&
                                                authState.user.roles.includes('ROLE_CUSTOMER')
                                            ) && (
                                                <div>
                                                    <Link to="/organizations/suppliers">
                                                        <ListItem
                                                            button
                                                            className={classes.itemDrawer}
                                                        >
                                                            <ListItemIcon className={classes.iconDrawer}><Business /></ListItemIcon>
                                                            <ListItemText primary={t('my_suppliers')}  />
                                                        </ListItem>
                                                    </Link>
                                                    <Link to="/organizations/resellers">
                                                        <ListItem
                                                            button
                                                            className={classes.itemDrawer}
                                                        >
                                                            <ListItemIcon className={classes.iconDrawer}><Business /></ListItemIcon>
                                                            <ListItemText primary={t('my_resellers')}  />
                                                        </ListItem>
                                                    </Link>
                                                </div>
                                            )
                                        }
                                        <Link to="/organizations_requests">
                                            <ListItem
                                                button
                                                className={classes.itemDrawer}
                                            >
                                                <ListItemIcon className={classes.iconDrawer}><Business /></ListItemIcon>
                                                <Badge
                                                    badgeContent={
                                                        appState.cachedData.organization_requests &&
                                                        Object.keys(appState.cachedData.organization_requests).length > 0 &&
                                                        Object.keys(appState.cachedData.organization_requests).filter(requestId => (
                                                            !appState.cachedData.organization_requests[requestId].status
                                                        )).length
                                                    }
                                                    color="secondary"
                                                >
                                                    <ListItemText primary={t('my_requests')}  />
                                                </Badge>
                                            </ListItem>
                                        </Link>
                                        <Link to="/organizations">
                                            <ListItem
                                                button
                                                className={classes.itemDrawer}
                                            >
                                                <ListItemIcon className={classes.iconDrawer}><Business /></ListItemIcon>
                                                <ListItemText primary={t('all_organizations')}  />
                                            </ListItem>
                                        </Link>
                                    </List>
                                    <Divider className={classes.divider} />
                                </div>
                            )
                        }
                        <List>
                            <Link to="/addresses">
                                <ListItem
                                    button
                                    className={classes.itemDrawer}
                                >
                                    <ListItemIcon className={classes.iconDrawer}><Room /></ListItemIcon>
                                    <ListItemText primary={t('addresses')} />
                                </ListItem>
                            </Link>
                        </List>
                    </div>
                )
            }
            {
                authState.user &&
                authState.user.roles.includes('ROLE_SUPERADMIN') && (
                    <div>
                        <Divider className={classes.divider} />
                        <span>
                            <Typography
                                className={classes.dividerFullWidth}
                                color="textSecondary"
                                display="block"
                                variant="caption"
                            >
                                {t('administration')} 
                            </Typography>
                        </span>
                        <List>
                            <Link to="/catalogs">
                                <ListItem
                                    button
                                    className={classes.itemDrawer}
                                >
                                    <ListItemIcon className={classes.iconDrawer}><VerticalSplit /></ListItemIcon>
                                    <ListItemText primary={t('catalogs')} />
                                </ListItem>
                            </Link>
                            <Link to="/orders">
                                <ListItem
                                    button
                                    className={classes.itemDrawer}
                                >
                                    <ListItemIcon className={classes.iconDrawer}><Archive /></ListItemIcon>
                                    <ListItemText primary={t('orders')}  />
                                </ListItem>
                            </Link>
                            <Link to="/organizations/suppliers">
                                <ListItem
                                    button
                                    className={classes.itemDrawer}
                                >
                                    <ListItemIcon className={classes.iconDrawer}><Business /></ListItemIcon>
                                    <ListItemText primary={t('suppliers')}  />
                                </ListItem>
                            </Link>
                            <Link to="/organizations/resellers">
                                <ListItem
                                    button
                                    className={classes.itemDrawer}
                                >
                                    <ListItemIcon className={classes.iconDrawer}><Business /></ListItemIcon>
                                    <ListItemText primary={t('resellers')}  />
                                </ListItem>
                            </Link>
                            <Link to="/organizations/customers">
                                <ListItem
                                    button
                                    className={classes.itemDrawer}
                                >
                                    <ListItemIcon className={classes.iconDrawer}><Business /></ListItemIcon>
                                    <ListItemText primary={t('customers')}  />
                                </ListItem>
                            </Link>
                            <Link to="/addresses">
                                <ListItem
                                    button
                                    className={classes.itemDrawer}
                                >
                                    <ListItemIcon className={classes.iconDrawer}><Room /></ListItemIcon>
                                    <ListItemText primary={t('addresses')}  />
                                </ListItem>
                            </Link>
                            <Link to="/users">
                                <ListItem
                                    button
                                    className={classes.itemDrawer}
                                >
                                    <ListItemIcon className={classes.iconDrawer}><SupervisorAccount /></ListItemIcon>
                                    <ListItemText primary={t('users')}  />
                                </ListItem>
                            </Link>
                        </List>
                        <Divider className={classes.divider} />
                    </div>
                )
            }
            <List>
                <ListItem
                    button
                    key="logout"
                    onClick={handleLogout}
                    className={classes.logoutDrawer}
                >
                    <ListItemIcon className={classes.icon}><ExitToApp /></ListItemIcon>
                    <ListItemText primary={t('logout')} />
                </ListItem>
                <ListItem className={classes.appLogo}>
                    <ListItemText primary={
                        <Logo type='menu' />
                    }/>
                </ListItem>
            </List>
        </div>
    );

    if (!authState.isLoggedIn) {
        return (
            <div className={classes.rootNotLoggedIn}>
                {props.children}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={appState.showMessage}
                    autoHideDuration={2000}
                    onClose={handleSnackbarClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="close"
                            color="inherit"
                            className={classes.closeSnackbar}
                            onClick={handleSnackbarClose}
                        >
                            <Close />
                        </IconButton>,
                    ]}
                >
                    <SnackbarContentWrapper
                        onClose={handleSnackbarClose}
                        variant={appState.variant}
                        message={appState.message}
                    />
                </Snackbar>
            </div>
        )
    } else {
        return (
            <div className={classes.rootLoggedIn}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={classes.appBar}
                >
                    <Toolbar className={classes.toolBar}>
                        {
                            appState.isMainPage &&
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerToggle}
                                edge="start"
                                className={classes.menuButton}
                            >
                                <Menu />
                            </IconButton>
                        }  
                        {
                            !appState.isMainPage &&
                            <IconButton
                                color="inherit"
                                aria-label="go back"
                                onClick={handleGoBack}
                                edge="start"
                                className={classes.menuButton}
                            >
                                <ChevronLeft />
                            </IconButton>
                        }
                        {
                            appState.pageTitle === '' &&
                            <Typography variant="h6" noWrap>
                                MyREF
                            </Typography>
                        }
                        {
                            appState.pageTitle !== '' &&
                            <Typography variant="h6" noWrap>
                                {t(appState.pageTitle)}
                            </Typography>
                        }
                        <div>
                            <IconButton
                                color="inherit"
                                aria-label="refresh"
                                className={classes.actionButton}
                                onClick={() => handleRefreshApp()}
                            >
                                <Refresh className={classes.toolBarLink}/>
                            </IconButton>
                            <IconButton
                                color="inherit"
                                aria-label="custom_action"
                                className={classes.actionButton}
                            >
                                {
                                    (
                                        authState &&
                                        authState.user &&
                                        authState.user.roles &&
                                        (
                                            authState.user.roles.includes('ROLE_SUPERADMIN') ||
                                            authState.user.roles.includes('ROLE_ADMIN')
                                        )
                                    ) && (
                                        <Link
                                            to="/"
                                            className={classes.toolBarLink}
                                        >
                                            <HomeIcon />
                                        </Link>
                                    )
                                }
                                {
                                    (
                                        authState &&
                                        authState.user &&
                                        authState.user.roles &&
                                        authState.user.roles.includes('ROLE_SUPPLIER') &&
                                        appState &&
                                        appState.cachedData &&
                                        appState.cachedData.orders
                                    ) && (
                                        <Link
                                            to="/received_orders"
                                            className={classes.toolBarLink}
                                        >
                                            <Badge
                                                badgeContent={
                                                    appState.cachedData.orders &&
                                                    Object.keys(appState.cachedData.orders).length > 0 &&
                                                    Object.keys(appState.cachedData.orders).filter(orderId => (
                                                        appState.cachedData.orders[orderId].status === 0
                                                    )).length
                                                }
                                                color="secondary"
                                            >
                                                <Archive />
                                            </Badge>
                                        </Link>
                                    )
                                }
                                {
                                    (
                                        authState &&
                                        authState.user &&
                                        authState.user.roles &&
                                        appState &&
                                        appState.cachedData &&
                                        appState.cachedData.carts &&
                                        appState.cachedData.carts[authState.user.cartId] &&
                                        (
                                            authState.user.roles.includes('ROLE_RESELLER') ||
                                            authState.user.roles.includes('ROLE_CUSTOMER')
                                        )
                                    ) && (
                                        <Link
                                            to={`/carts/show/${authState.user.cartId}`}
                                            className={classes.toolBarLink}
                                        >
                                            <Badge
                                                badgeContent={
                                                    appState.cachedData.carts[authState.user.cartId].cartItems.length
                                                }
                                                color="secondary"
                                            >
                                                <ShoppingCart />
                                            </Badge>
                                        </Link>
                                    )
                                }
                            </IconButton>
                        </div>
                    </Toolbar>
                    {
                        (
                            appState.isLoading
                        ) &&
                        <LinearProgress
                            size={24}
                            thickness={4}
                            className={classes.linearProgress}
                        />
                    }
                </AppBar>
                <nav className={classes.drawer} aria-label="drawer">
                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor="left"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            onClick={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            {drawerContent}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            variant="permanent"
                            anchor="left"
                            open
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            {drawerContent}
                        </Drawer>
                    </Hidden>
                </nav>
                <main
                    className={classes.content}
                >
                    <div className={classes.drawerHeader} />
                    {props.children}
                    <div className={classes.footer} />
                </main>
                {
                    (
                        appState.isRefreshing
                    ) &&
                    <div className={classes.refreshingApp}>
                        <CircularProgress
                            size={18}
                            thickness={4}
                            className={classes.circularProgress}
                        />
                        <span>{t('update_from_server')}</span>
                    </div>
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={appState.showMessage}
                    autoHideDuration={2000}
                    onClose={handleSnackbarClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="close"
                            color="inherit"
                            className={classes.closeSnackbar}
                            onClick={handleSnackbarClose}
                        >
                            <Close />
                        </IconButton>,
                    ]}
                >
                    <SnackbarContentWrapper
                        onClose={handleSnackbarClose}
                        variant={appState.variant}
                        message={appState.message}
                    />
                </Snackbar>
            </div>
        )
    }
};

export default HomeDrawer;
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Button,
    Container,
    FormControl,
    FormGroup,
    Grid,
    InputLabel,
    makeStyles,
    NativeSelect,
    TextField
} from "@material-ui/core";
import {AppContext, AuthContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {getIdFromUri, parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    option: {
        color: theme.palette.text.customPrimary,
    },
}));

const Create = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        forceRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const classes = useStyles();
    const [options, setOptions] = useState([]);
    const handleCreate = () => {
        fetchInitAppState();
        fetch(
            'custom_delivery_costs',
            {
                method: 'POST',
                body: {
                    seller: `/api/organizations/${authState.user.organizationId}`,
                    purchaser: `/api/organizations/${props.match.params.idPurchaser}`,
                    type: parseInt(inputs.type),
                    value: parseFloat(inputs.value)
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                forceRefreshResourceAppState(`custom_delivery_costs?seller=${authState.user.organizationId}&purchaser=${props.match.params.idPurchaser}`);
                showMessageAppState('success', t('custom_delivery_cost_added'));
                props.history.goBack();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };
    const { inputs, handleInputChange, handleSubmit } = useForm(handleCreate);
    const { fetch } = useDataAccess();

    useEffect(() => {
        setPageInfos('new_custom_delivery_cost', false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        initOptions();
        addRefreshResourceAppState(`custom_delivery_costs?seller=${authState.user.organizationId}&purchaser=${props.match.params.idPurchaser}`);
        // eslint-disable-next-line
    }, [props.match.params.idPurchaser]);

    useEffect(() => {
        initOptions();
        // eslint-disable-next-line
    }, [appState.cachedData.custom_delivery_costs]);

    const initOptions = () => {
        let tmpAvailableOptions = [
            {'id': 0, 'value': 'standard'},
            {'id': 1, 'value': 'express'}
        ];
        if(
            authState &&
            authState.user &&
            authState.user.organizationId &&
            appState &&
            appState.cachedData &&
            appState.cachedData.custom_delivery_costs
        ) {
            Object.keys(appState.cachedData.custom_delivery_costs).map(customDeliveryCostId => {
                if(
                    getIdFromUri(appState.cachedData.custom_delivery_costs[customDeliveryCostId].seller) === parseInt(authState.user.organizationId) &&
                    getIdFromUri(appState.cachedData.custom_delivery_costs[customDeliveryCostId].purchaser) === parseInt(props.match.params.idPurchaser)
                ) {
                    if(appState.cachedData.custom_delivery_costs[customDeliveryCostId].type === 0) {
                        return tmpAvailableOptions = tmpAvailableOptions.filter(option => option.id !== 0);
                    } else if(appState.cachedData.custom_delivery_costs[customDeliveryCostId].type === 1) {
                        return tmpAvailableOptions = tmpAvailableOptions.filter(option => option.id !== 1);
                    }
                }
                return false;
            });
        }
        setOptions(tmpAvailableOptions);
    };

    return (
        <Container>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
            >
                {
                    appState.isError &&
                    appState.error &&
                    (
                        <Grid item xs={12} className="text-center">
                            <div className="alert alert-danger" role="alert">
                                <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
                                {t(appState.error.toString().slice(7))}
                            </div>
                        </Grid>
                    )
                }
                <form onSubmit={handleSubmit} className="container">
                    <FormGroup>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="type">
                                {t('delivery_type')}
                            </InputLabel>
                            <NativeSelect
                                required
                                value={inputs.type}
                                onChange={handleInputChange}
                                disabled={
                                    appState.isLoading ||
                                    appState.isRefreshing ||
                                    (
                                        options &&
                                        options.length === 0
                                    )
                                }
                                inputProps={{
                                    name: 'type',
                                    id: 'type',
                                }}
                            >
                                <option value='' />
                                {
                                    options &&
                                    options.length > 0 &&
                                    options.map(option => {
                                        return <option key={option.id} value={option.id} className={classes.option}>{t(option.value)}</option>
                                    })
                                }
                            </NativeSelect>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                id="value"
                                name="value"
                                label={t('custom_value')}
                                type="number"
                                inputProps={{ step: "0.01" }}
                                onChange={handleInputChange}
                                value={inputs.value ? inputs.value : ''}
                                disabled={
                                    appState.isLoading ||
                                    appState.isRefreshing ||
                                    (
                                        options &&
                                        options.length === 0
                                    )
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={
                                    appState.isLoading ||
                                    appState.isRefreshing ||
                                    (
                                        options &&
                                        options.length === 0
                                    )
                                }
                            >
                                {t('create')}
                            </Button>
                        </FormControl>
                    </FormGroup>
                </form>
            </Grid>
        </Container>
    )
};

export default Create;
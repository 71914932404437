import React from "react";
import {Link} from 'react-router-dom';

export const pagination = data => {
    const view = data && data['hydra:view'];
    if (!view) return;

    const {
        'hydra:first': first,
        'hydra:previous': previous,
        'hydra:next': next,
        'hydra:last': last
    } = view;

    return (
        <nav aria-label="Page navigation">
            <Link
                to="."
                className={`btn btn-primary${previous ? '' : ' disabled'}`}
            >
                <span aria-hidden="true">&lArr;</span> First
            </Link>
            <Link
                to={
                    !previous || previous === first ? '.' : encodeURIComponent(previous)
                }
                className={`btn btn-primary${previous ? '' : ' disabled'}`}
            >
                <span aria-hidden="true">&larr;</span> Previous
            </Link>
            <Link
                to={next ? encodeURIComponent(next) : '#'}
                className={`btn btn-primary${next ? '' : ' disabled'}`}
            >
                Next <span aria-hidden="true">&rarr;</span>
            </Link>
            <Link
                to={last ? encodeURIComponent(last) : '#'}
                className={`btn btn-primary${next ? '' : ' disabled'}`}
            >
                Last <span aria-hidden="true">&rArr;</span>
            </Link>
        </nav>
    );
};

export const renderLinks = (type, items, linkText = '') => {
    if (Array.isArray(items)) {
        return items.map((item, i) => (
            <div key={i}>{this.renderLinks(type, item)}</div>
        ));
    }

    if(type === 'organizations') {
        return (
            <Link to={`/${type}/show/organizations/${encodeURIComponent(items)}`}>{ '' !== linkText ? linkText : items}</Link>
        )
    } else {
        return (
            <Link to={`/${type}/show/${encodeURIComponent(items)}`}>{ '' !== linkText ? linkText : items}</Link>
        )
    }
};

export const parseError = error => {
    if(typeof error === 'string') {
        if(error.includes(process.env.REACT_APP_API_ENTRYPOINT)) {
            return error.split(process.env.REACT_APP_API_ENTRYPOINT)[1].slice(4);
        }
        return error;
    } else {
        return error.toString();
    }
};

export const getIdFromUri = uri => {
    if(uri) {
        if(uri['@id']) {
            uri = uri['@id'];
        } else if(uri.id) {
            uri = uri.id;
        }
        if(uri !== '' && typeof uri === "string") {
            const items = uri.split('/');
            return parseInt(items[items.length-1]);
        }
    }
    return 0;
};
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import {Loader} from './hooks/common';

ReactDOM.render(
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

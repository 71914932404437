import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Container, FormControl, FormGroup, Grid, makeStyles, TextField} from "@material-ui/core";
import {AppContext, AuthContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const Update = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        setPageInfos,
        showMessageAppState
    } = useContext(AppContext);
    const classes = useStyles();
    const [organization, setOrganization] = useState(null);
    const {fetch} = useDataAccess();
    const handleUpdate = () => {
        fetchInitAppState();
        fetch(
            `organizations/${props.match.params.id}`,
            {
                method: 'PUT',
                body: {
                    name: inputs.name,
                    identificationNumber: inputs.identificationNumber
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                addRefreshResourceAppState(`${props.match.params.type}/${props.match.params.id}`);
                showMessageAppState('success', t('organization_updated'));
                props.history.goBack();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };
    const {inputs, handleInputChange, handleSubmit, setInputs} = useForm(handleUpdate);

    useEffect(() => {
        setPageInfos('edit_organization', false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setOrganizationFromCache();
        addRefreshResourceAppState(`${props.match.params.type}/${props.match.params.id}`);
        // eslint-disable-next-line
    }, [props.match.params.id]);

    useEffect(() => {
        setOrganizationFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData[props.match.params.type]]);

    useEffect(() => {
        setInputs(inputs => ({...inputs, ...organization}));
        // eslint-disable-next-line
    }, [organization]);

    const setOrganizationFromCache = () => {
        if(
            authState.user &&
            !authState.user.roles.includes('ROLE_SUPERADMIN') &&
            appState.cachedData &&
            appState.cachedData[props.match.params.type] &&
            appState.cachedData[props.match.params.type][props.match.params.id]
        ) {
            setOrganization(appState.cachedData[props.match.params.type][props.match.params.id]);
        } else if(
            authState.user &&
            authState.user.roles.includes('ROLE_SUPERADMIN') &&
            appState.cachedData &&
            appState.cachedData.organizations &&
            appState.cachedData.organizations[props.match.params.id]
        ) {
            setOrganization(appState.cachedData.organizations[props.match.params.id]);
        }
    };

    return (
        <Container>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
            >
                {
                    appState.isError &&
                    appState.error &&
                    (
                        <Grid item xs={12} className="text-center">
                            <div className="alert alert-danger" role="alert">
                                <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
                                {appState.error}
                            </div>
                        </Grid>
                    )
                }
                <form onSubmit={handleSubmit} className="container">
                    <FormGroup>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="name"
                                name="name"
                                label={t('organization_name')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.name ? inputs.name : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="identificationNumber"
                                name="identificationNumber"
                                label={t('identification_number')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.identificationNumber ? inputs.identificationNumber : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            >
                                {t('update')}
                            </Button>
                        </FormControl>
                    </FormGroup>
                </form>
            </Grid>
        </Container>
    )
};

export default Update;
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Redirect} from "react-router-dom";
import {
    Typography,
    FormControl,
    Button,
    Checkbox, Container,
    CssBaseline, Grid, Hidden,
    List as MaterialList,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    makeStyles,
} from "@material-ui/core";
import {
    ArrowDropDown,
    ArrowDropUp, ArrowLeft,
    ArrowRight,
    ChevronLeft,
    ChevronRight,
    ExpandLess,
    ExpandMore
} from "@material-ui/icons";
import {AppContext, AuthContext} from "../../context";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: 'auto',
    },
    paper: {
        width: 'auto',
        height: 300,
        overflow: 'auto',
        margin: 'auto',
        color:  theme.palette.text.customPrimary,
    },
    title: {
        margin: theme.spacing(1),
    },
    checkBox: {
        color:  theme.palette.text.customPrimary,
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

function not(a, b) {
    return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter(value => b.indexOf(value) !== -1);
}

const ManageLinks = props => {
    const { t } = useTranslation();
    const {authState} = useContext(AuthContext);
    const {appState} = useContext(AppContext);
    const classes = useStyles();
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    useEffect(() => {
        if(props.element) {
            handleInitList(props.element);
        }
        // eslint-disable-next-line
    }, [props.element]);

    useEffect(() => {
        // eslint-disable-next-line
    }, [right]);

    const handleInitList = grantedOrganizations => {
        let organizations = [];
        if(appState.cachedData && appState.cachedData.customers) {
            Object.keys(appState.cachedData.customers).map(customerId => {
                if(!grantedOrganizations.find(currentGrantedOrganization => currentGrantedOrganization.id === parseInt(customerId))) {
                    return organizations.push(appState.cachedData.customers[customerId])
                } else {
                    return false;
                }
            });
        }
        if(appState.cachedData && appState.cachedData.resellers) {
            Object.keys(appState.cachedData.resellers).map(resellerId => {
                if(!grantedOrganizations.find(currentGrantedOrganization => currentGrantedOrganization.id === parseInt(resellerId))) {
                    return organizations.push(appState.cachedData.resellers[resellerId])
                } else {
                    return false;
                }
            });
        }
        setLeft(organizations);
        setRight(grantedOrganizations);
    };

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const handleSubmit = () => {
        props.submit(right);
    };

    const customList = (title, items) => (
        <Paper className={classes.paper}>
            <Typography className={classes.title}>{t(title)}</Typography>
            <MaterialList dense component="div" role="list">
                {
                    items.map(value => {
                        return (
                            <ListItem key={value['@id']} role="listitem" button onClick={handleToggle(value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{'aria-labelledby': value.id}}
                                        className={classes.checkBox}
                                        disabled={
                                            appState.isLoading &&
                                            appState.isRefreshing
                                        }
                                    />
                                </ListItemIcon>
                                <ListItemText id={value.id} primary={value.name}/>
                            </ListItem>
                        );
                    })
                }
            </MaterialList>
        </Paper>
    );

    if (
        !authState.user.roles.includes('ROLE_SUPERADMIN') &&
        !authState.user.roles.includes('ROLE_ADMIN') &&
        !authState.user.roles.includes('ROLE_SUPPLIER') &&
        !authState.user.roles.includes('ROLE_RESELLER')
    ) {
        return (<Redirect to='/'/>)
    } else {
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <Container>
                    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                        <Grid item xs={12} lg={4}>
                            {
                                customList(
                                    'available_organizations',
                                    left.filter(currentOrganization => currentOrganization.id !== authState.user.organizationId)
                                )
                            }
                        </Grid>
                        <Hidden lgUp implementation="css">
                            <Grid item xs={12}>
                                <Grid container direction="row" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleAllRight}
                                        disabled={
                                            left.length === 0 ||
                                            appState.isLoading ||
                                            appState.isRefreshing
                                        }
                                        aria-label="move all bottom"
                                    >
                                        <ArrowDropDown/>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedRight}
                                        disabled={
                                            leftChecked.length === 0 ||
                                            appState.isLoading ||
                                            appState.isRefreshing
                                        }
                                        aria-label="move selected bottom"
                                    >
                                        <ExpandMore/>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedLeft}
                                        disabled={
                                            rightChecked.length === 0 ||
                                            appState.isLoading ||
                                            appState.isRefreshing
                                        }
                                        aria-label="move selected top"
                                    >
                                        <ExpandLess/>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleAllLeft}
                                        disabled={
                                            right.length === 0 ||
                                            appState.isLoading ||
                                            appState.isRefreshing
                                        }
                                        aria-label="move all top"
                                    >
                                        <ArrowDropUp/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Hidden mdDown implementation="css">
                            <Grid item xs={12} lg={4}>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleAllRight}
                                        disabled={
                                            left.length === 0 ||
                                            appState.isLoading ||
                                            appState.isRefreshing
                                        }
                                        aria-label="move all right"
                                    >
                                        <ArrowRight/>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedRight}
                                        disabled={
                                            leftChecked.length === 0 ||
                                            appState.isLoading ||
                                            appState.isRefreshing
                                        }
                                        aria-label="move selected right"
                                    >
                                        <ChevronRight/>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedLeft}
                                        disabled={
                                            rightChecked.length === 0 ||
                                            appState.isLoading
                                        }
                                        aria-label="move selected left"
                                    >
                                        <ChevronLeft/>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleAllLeft}
                                        disabled={
                                            right.length === 0 ||
                                            appState.isLoading ||
                                            appState.isRefreshing
                                        }
                                        aria-label="move all left"
                                    >
                                        <ArrowLeft/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} lg={4}>
                            {
                                customList(
                                    'granted_organizations',
                                    right.filter(currentOrganization => currentOrganization.id !== authState.user.organizationId)
                                )
                            }
                        </Grid>
                    </Grid>
                    <Grid container justify="center" alignItems="center" className={classes.root}>
                        <FormControl className={classes.formControl}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                onClick={handleSubmit}
                            >
                                {t('submit')}
                            </Button>
                        </FormControl>
                    </Grid>
                </Container>
            </div>
        )
    }
};

export default ManageLinks;